import { SideNavigationProps } from "@cloudscape-design/components";

/**
 * Define your Navigation Items here
 */
export const NavItems: SideNavigationProps.Item[] = [
  // { text: "[Sample] Shopping Lists", type: "link", href: "/" },
  { text: "Models Management", type: "link", href: "/models" },
  { text: "Your Trainer Profile", type: "link", href: "/profile" },
  { text: "Leaderboard Submission", type: "link", href: "/leaderboard" },
  {
    text: "Validate Training Data",
    type: "link",
    href: "/validate-training-data",
  },
  { type: "divider" },
  // {
  //   text: "Developer Tools",
  //   type: "expandable-link-group",
  //   href: "#",
  //   defaultExpanded: false,
  //   items: [{ text: "API Explorer", type: "link", href: "/apiExplorer" }],
  // },
];
