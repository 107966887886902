/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Defines the id attribute of the Shopping List
 * @export
 * @interface PutLeaderboardResponseContent
 */
export interface PutLeaderboardResponseContent {
    /**
     * 
     * @type {string}
     * @memberof PutLeaderboardResponseContent
     */
    leaderboardId: string;
}


/**
 * Check if a given object implements the PutLeaderboardResponseContent interface.
 */
export function instanceOfPutLeaderboardResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "leaderboardId" in value;

    return isInstance;
}

export function PutLeaderboardResponseContentFromJSON(json: any): PutLeaderboardResponseContent {
    return PutLeaderboardResponseContentFromJSONTyped(json, false);
}

export function PutLeaderboardResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutLeaderboardResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'leaderboardId': json['leaderboardId'],
    };
}

export function PutLeaderboardResponseContentToJSON(value?: PutLeaderboardResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'leaderboardId': value.leaderboardId,
    };
}

