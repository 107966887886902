import {
  Box,
  Button,
  Cards,
  ColumnLayout,
  Container,
  ContentLayout,
  // FileUpload,
  Form,
  FormField,
  Header,
  Input,
  // RadioGroup,
  SpaceBetween,
  Wizard,
} from "@cloudscape-design/components";
import {
  ModelStructure,
  usePutModel,
} from "myapi-typescript-react-query-hooks";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModelType } from "./Models/ModelTypeModel";
import { UserContext } from "../../layouts/App";

const RegisterModelPage: React.FC = () => {
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [modelName, setModelName] = useState<string>("");
  const [trainingJobName, setTrainingJobName] = useState<string>("");
  const [trainingJobNameErrorMsg, setTrainingJobNameErrorMsg] =
    useState<string>("");
  const [modelNameErrorMsg, setModelNameErrorMsg] = useState<string>("");
  // const [fileformat, setFileFormat] = useState<string>("");
  // const [fileFormatErrorMsg, setFileFormatErrorMsg] = useState<string>("");

  // const [fileOutput, setFileOutput] = useState<File[]>([]);
  // const [fileOutputErrorMsg, setFileOutputErrorMsg] = useState<string>("");
  const putModels = usePutModel();
  const userInfo = useContext(UserContext);
  const navigate = useNavigate();
  const modelTypes: ModelType[] = [
    {
      name: "SageMaker",
      description: "Model trained via SageMaker",
      type: "sagemaker",
      isDisabled: false,
    },
    {
      name: "Bedrock (Coming Soon!)",
      description: "Model fine tuned via Bedrock",
      type: "bedrock",
      isDisabled: true,
    },
  ];
  const [selectedModelType, setSelectedModelType] = useState<ModelType[]>([
    modelTypes[0],
  ]);

  const handleSubmitNewModel: VoidFunction =
    async function handleSubmitNewModel() {
      const newModel: ModelStructure = {
        userId: userInfo.userId,
        name: modelName,
        modelOutputs: trainingJobName ?? "",
        trainingJobName: trainingJobName ?? "",
        modelId: "",
      };
      console.log(newModel);
      await putModels
        .mutateAsync({
          //@ts-ignore
          putModelRequestContent: newModel,
        })
        .then((data) => {
          const modelId = data.modelId;
          console.log(modelId);
          navigate(`/models`);
        });

      //OLD LOGIC (when submitting model.out.json)
      // if (fileOutput.length > 0) {
      //   const fileOutputString = fileOutput[0].text().then((text) => {
      //     return text;
      //   });
      //   const newModel: ModelStructure = {
      //     userId: userInfo.userId,
      //     name: modelName,
      //     modelOutputs: await fileOutputString,
      //     modelS3FileLink: "",
      //     modelId: "",
      //   };
      //   console.log(newModel);
      //   await putModels
      //     .mutateAsync({
      //       putModelRequestContent: newModel,
      //     })
      //     .then((data) => {
      //       const modelId = data.modelId;
      //       console.log(modelId);
      //       navigate(`/models`);
      //     });
      // }

      // console.log(fileformat);
      // console.log(fileOutput);
    };
  function validateThenNavigateRegisterModelForm(detail: any) {
    if (activeStepIndex != 0) {
      setActiveStepIndex(detail.requestedStepIndex);
    } else {
      // if (modelName && fileformat && fileOutput && fileOutput.length > 0) {
      if (modelName && trainingJobName) {
        setActiveStepIndex(detail.requestedStepIndex);
      }
      if (!modelName) {
        setModelNameErrorMsg("Please provide a model name");
      }
      if (!trainingJobName) {
        setTrainingJobNameErrorMsg("Please provide a training job name");
      }
      // if (!fileformat) {
      //   setFileFormatErrorMsg("Please choose which file format to upload");
      // }
      // if (fileOutput.length == 0) {
      //   setFileOutputErrorMsg("Please upload the output file");
      // }
    }
  }
  const i18nStrings = {
    stepNumberLabel: (stepNumber: number) => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber: number, stepsCount: number) =>
      `Step ${stepNumber} of ${stepsCount}`,
    skipToButtonLabel: (step: { title: string }, _stepNumber: number) =>
      `Skip to ${step.title}`,
    navigationAriaLabel: "Steps",
    cancelButton: "Cancel",
    previousButton: "Previous",
    nextButton: "Next",
    optional: "optional",
  };

  const steps = [
    {
      title: "Provide Model Information",
      content: (
        <>
          <SpaceBetween direction="vertical" size="l">
            <Container
              header={
                <Header variant="h1" description="Model description">
                  Model Name
                </Header>
              }
            >
              <FormField errorText={modelNameErrorMsg}>
                <Input
                  placeholder="Your model name"
                  value={modelName}
                  onChange={(event) => {
                    if (modelName) {
                      setModelNameErrorMsg("");
                    }
                    setModelName(event.detail.value);
                  }}
                />
              </FormField>
            </Container>
            <Container
              header={
                <Header
                  variant="h1"
                  description="Training Job name (get from AWS Sagemaker's studio)"
                >
                  Training Job Name
                </Header>
              }
            >
              <FormField errorText={trainingJobNameErrorMsg}>
                <Input
                  placeholder="Your training job name"
                  value={trainingJobName}
                  onChange={(event) => {
                    if (trainingJobName) {
                      setTrainingJobNameErrorMsg("");
                    }
                    setTrainingJobName(event.detail.value);
                  }}
                />
              </FormField>
            </Container>
            <Cards
              onSelectionChange={({ detail }) =>
                setSelectedModelType(detail?.selectedItems ?? [])
              }
              selectedItems={selectedModelType}
              cardDefinition={{
                header: (item) => item.name,
                sections: [
                  {
                    id: "description",
                    content: (item) => item.description,
                  },
                  {
                    id: "type",
                    content: (item) => item.type,
                  },
                ],
              }}
              isItemDisabled={(item) => item.isDisabled}
              cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
              entireCardClickable
              items={modelTypes}
              loadingText="Loading resources"
              selectionType="single"
              trackBy="name"
              visibleSections={["description"]}
            />
            {/* <Container
              header={
                <Header
                  variant="h1"
                  description="Select format of model output you are uploading"
                >
                  Model Output Format
                </Header>
              }
            >
              <FormField errorText={fileFormatErrorMsg}>
                <RadioGroup
                  onChange={({ detail }) => {
                    setFileFormatErrorMsg("");
                    setFileFormat(detail.value);
                  }}
                  value={fileformat}
                  items={[
                    { value: "JSON", label: ".json" },
                    { value: "ZIP", label: ".tar.gz" },
                    // TODO: Do we include S3 URI as model submission?
                    // { value: "s3_uri", label: "S3 URI"} T
                  ]}
                />
              </FormField>
            </Container>
            <Container
              header={
                <Header
                  variant="h1"
                  description="Upload model output in the correct chosen format"
                >
                  Upload File in {fileformat}
                </Header>
              }
            >
              <FormField errorText={fileOutputErrorMsg}>
                <FileUpload
                  onChange={({ detail }) => {
                    setFileOutputErrorMsg("");
                    setFileOutput(detail.value);
                  }}
                  value={fileOutput}
                  i18nStrings={{
                    uploadButtonText: (isMultiple) =>
                      isMultiple ? "Choose files" : "Choose file",
                    dropzoneText: (isMultiple) =>
                      isMultiple
                        ? "Drop files to upload"
                        : "Drop file to upload",
                    removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error",
                  }}
                  showFileLastModified
                  showFileSize
                  showFileThumbnail
                  tokenLimit={3}
                />
              </FormField>
            </Container>*/}
          </SpaceBetween>
        </>
      ),
      isOptional: false,
    },
    {
      title: "Review and submit",
      content: (
        <SpaceBetween size="xs">
          <Header
            variant="h2"
            actions={
              <Button onClick={() => setActiveStepIndex(0)}>Edit</Button>
            }
          >
            Model Registration
          </Header>
          <Container>
            <ColumnLayout variant="text-grid">
              <div>
                <Box variant="awsui-key-label">Model name</Box>
                <div>{modelName}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Training job name</Box>
                <div>{trainingJobName}</div>
              </div>
              {/* <div>
                <Box variant="awsui-key-label">
                  Model output in {fileformat} format
                </Box>
                <div>{fileOutput[0]?.name || ""}</div>
              </div> */}
            </ColumnLayout>
          </Container>
        </SpaceBetween>
      ),
    },
  ];
  return (
    <>
      <ContentLayout
        header={
          <SpaceBetween size="m">
            <Header variant="h1">Model Registration</Header>
          </SpaceBetween>
        }
      >
        <Form>
          <Wizard
            i18nStrings={i18nStrings}
            submitButtonText="Register Model"
            onNavigate={({ detail }) => {
              validateThenNavigateRegisterModelForm(detail);
            }}
            onSubmit={handleSubmitNewModel}
            onCancel={() => navigate("/models")}
            activeStepIndex={activeStepIndex}
            allowSkipTo
            steps={steps}
          />
        </Form>
      </ContentLayout>
    </>
  );
};

export default RegisterModelPage;
