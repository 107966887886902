/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShoppingList } from './ShoppingList';
import {
    ShoppingListFromJSON,
    ShoppingListFromJSONTyped,
    ShoppingListToJSON,
} from './ShoppingList';

/**
 * Extends outputs for "list" type operations to return pagination details
 * @export
 * @interface GetShoppingListsResponseContent
 */
export interface GetShoppingListsResponseContent {
    /**
     * Pass this in the next request for another page of results
     * @type {string}
     * @memberof GetShoppingListsResponseContent
     */
    nextToken?: string;
    /**
     * List of Shopping List
     * @type {Array<ShoppingList>}
     * @memberof GetShoppingListsResponseContent
     */
    shoppingLists: Array<ShoppingList>;
}


/**
 * Check if a given object implements the GetShoppingListsResponseContent interface.
 */
export function instanceOfGetShoppingListsResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shoppingLists" in value;

    return isInstance;
}

export function GetShoppingListsResponseContentFromJSON(json: any): GetShoppingListsResponseContent {
    return GetShoppingListsResponseContentFromJSONTyped(json, false);
}

export function GetShoppingListsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetShoppingListsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextToken': !exists(json, 'nextToken') ? undefined : json['nextToken'],
        'shoppingLists': ((json['shoppingLists'] as Array<any>).map(ShoppingListFromJSON)),
    };
}

export function GetShoppingListsResponseContentToJSON(value?: GetShoppingListsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextToken': value.nextToken,
        'shoppingLists': ((value.shoppingLists as Array<any>).map(ShoppingListToJSON)),
    };
}

