// Import models
import type {
  BadRequestErrorResponseContent,
  DeleteLeaderboardResponseContent,
  DeleteModelResponseContent,
  DeleteShoppingListResponseContent,
  GetLeaderboardResponseContent,
  GetLeaderboardSubmissionResponseContent,
  GetModelConfigResponseResponseContent,
  GetModelListResponseContent,
  GetModelRankingResponseContent,
  GetModelResponseResponseContent,
  GetShoppingListsResponseContent,
  GetUserLeaderboardResponseContent,
  GetUserProfileResponseContent,
  InternalFailureErrorResponseContent,
  NotAuthorizedErrorResponseContent,
  NotFoundErrorResponseContent,
  PutLeaderboardRequestContent,
  PutLeaderboardResponseContent,
  PutLeaderboardSubmissionRequestContent,
  PutLeaderboardSubmissionResponseContent,
  PutModelConfigRequestContent,
  PutModelConfigResponseContent,
  PutModelRequestContent,
  PutModelResponseContent,
  PutShoppingListRequestContent,
  PutShoppingListResponseContent,
  PutUserExitLeaderboardRequestContent,
  PutUserExitLeaderboardResponseContent,
  PutUserJoinLeaderboardRequestContent,
  PutUserJoinLeaderboardResponseContent,
  PutUserProfileRequestContent,
  PutUserProfileResponseContent,
  
} from '../models';

// Import request parameter interfaces
import {
    DeleteLeaderboardRequest,
    DeleteModelRequest,
    DeleteShoppingListRequest,
    GetLeaderboardRequest,
    GetLeaderboardSubmissionRequest,
    GetModelConfigResponseRequest,
    GetModelListRequest,
    GetModelRankingRequest,
    GetModelResponseRequest,
    GetShoppingListsRequest,
    GetUserLeaderboardRequest,
    GetUserProfileRequest,
    PutLeaderboardRequest,
    PutLeaderboardSubmissionRequest,
    PutModelRequest,
    PutModelConfigRequest,
    PutShoppingListRequest,
    PutUserExitLeaderboardRequest,
    PutUserJoinLeaderboardRequest,
    PutUserProfileRequest,
    
    
} from '..';

import { ResponseError } from '../runtime';
import { DefaultApi } from './DefaultApi';
import { DefaultApiDefaultContext } from "./DefaultApiClientProvider";

import {
    useQuery,
    UseQueryResult,
    UseQueryOptions,
    useInfiniteQuery,
    UseInfiniteQueryResult,
    UseInfiniteQueryOptions,
    useMutation,
    UseMutationOptions,
    UseMutationResult
} from "@tanstack/react-query";
import { createContext, useContext } from "react";

/**
 * Context for the API client used by the hooks.
 */
export const DefaultApiClientContext = createContext<DefaultApi | undefined>(undefined);

const NO_API_ERROR = new Error(`DefaultApi client missing. Please ensure you have instantiated the DefaultApiClientProvider with a client instance.`);


/**
 * useMutation hook for the DeleteLeaderboard operation
 */
export const useDeleteLeaderboard = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<DeleteLeaderboardResponseContent, TError, DeleteLeaderboardRequest>, 'mutationFn'>
): UseMutationResult<DeleteLeaderboardResponseContent, TError, DeleteLeaderboardRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DeleteLeaderboardRequest) => api.deleteLeaderboard(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the DeleteModel operation
 */
export const useDeleteModel = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<DeleteModelResponseContent, TError, DeleteModelRequest>, 'mutationFn'>
): UseMutationResult<DeleteModelResponseContent, TError, DeleteModelRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DeleteModelRequest) => api.deleteModel(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the DeleteShoppingList operation
 */
export const useDeleteShoppingList = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<DeleteShoppingListResponseContent, TError, DeleteShoppingListRequest>, 'mutationFn'>
): UseMutationResult<DeleteShoppingListResponseContent, TError, DeleteShoppingListRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: DeleteShoppingListRequest) => api.deleteShoppingList(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetLeaderboard operation
 */
export const useGetLeaderboard = <TError = ResponseError>(
    params: GetLeaderboardRequest,
    options?: Omit<UseQueryOptions<GetLeaderboardResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetLeaderboardResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getLeaderboard", params], () => api.getLeaderboard(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetLeaderboardSubmission operation
 */
export const useGetLeaderboardSubmission = <TError = ResponseError>(
    params: GetLeaderboardSubmissionRequest,
    options?: Omit<UseQueryOptions<GetLeaderboardSubmissionResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetLeaderboardSubmissionResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getLeaderboardSubmission", params], () => api.getLeaderboardSubmission(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetModelConfigResponse operation
 */
export const useGetModelConfigResponse = <TError = ResponseError>(
    params: GetModelConfigResponseRequest,
    options?: Omit<UseQueryOptions<GetModelConfigResponseResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetModelConfigResponseResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getModelConfigResponse", params], () => api.getModelConfigResponse(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetModelList operation
 */
export const useGetModelList = <TError = ResponseError>(
    params: GetModelListRequest,
    options?: Omit<UseQueryOptions<GetModelListResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetModelListResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getModelList", params], () => api.getModelList(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetModelRanking operation
 */
export const useGetModelRanking = <TError = ResponseError>(
    params: GetModelRankingRequest,
    options?: Omit<UseQueryOptions<GetModelRankingResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetModelRankingResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getModelRanking", params], () => api.getModelRanking(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetModelResponse operation
 */
export const useGetModelResponse = <TError = ResponseError>(
    params: GetModelResponseRequest,
    options?: Omit<UseQueryOptions<GetModelResponseResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetModelResponseResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getModelResponse", params], () => api.getModelResponse(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useInfiniteQuery hook for the GetShoppingLists operation
 */
export const useGetShoppingLists = <TError = ResponseError>(
    params: GetShoppingListsRequest,
    options?: Omit<UseInfiniteQueryOptions<GetShoppingListsResponseContent, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
): UseInfiniteQueryResult<GetShoppingListsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useInfiniteQuery(["getShoppingLists", params], ({ pageParam }) => api.getShoppingLists({ ...params, nextToken: pageParam }), {
        getNextPageParam: (response) => response.nextToken,
        context: DefaultApiDefaultContext,
        ...options as any,
    });
};

/**
 * useQuery hook for the GetUserLeaderboard operation
 */
export const useGetUserLeaderboard = <TError = ResponseError>(
    params: GetUserLeaderboardRequest,
    options?: Omit<UseQueryOptions<GetUserLeaderboardResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetUserLeaderboardResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getUserLeaderboard", params], () => api.getUserLeaderboard(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useQuery hook for the GetUserProfile operation
 */
export const useGetUserProfile = <TError = ResponseError>(
    params: GetUserProfileRequest,
    options?: Omit<UseQueryOptions<GetUserProfileResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetUserProfileResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getUserProfile", params], () => api.getUserProfile(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};


/**
 * useMutation hook for the PutLeaderboard operation
 */
export const usePutLeaderboard = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<PutLeaderboardResponseContent, TError, PutLeaderboardRequest>, 'mutationFn'>
): UseMutationResult<PutLeaderboardResponseContent, TError, PutLeaderboardRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: PutLeaderboardRequest) => api.putLeaderboard(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the PutLeaderboardSubmission operation
 */
export const usePutLeaderboardSubmission = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<PutLeaderboardSubmissionResponseContent, TError, PutLeaderboardSubmissionRequest>, 'mutationFn'>
): UseMutationResult<PutLeaderboardSubmissionResponseContent, TError, PutLeaderboardSubmissionRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: PutLeaderboardSubmissionRequest) => api.putLeaderboardSubmission(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the PutModel operation
 */
export const usePutModel = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<PutModelResponseContent, TError, PutModelRequest>, 'mutationFn'>
): UseMutationResult<PutModelResponseContent, TError, PutModelRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: PutModelRequest) => api.putModel(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the PutModelConfig operation
 */
export const usePutModelConfig = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<PutModelConfigResponseContent, TError, PutModelConfigRequest>, 'mutationFn'>
): UseMutationResult<PutModelConfigResponseContent, TError, PutModelConfigRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: PutModelConfigRequest) => api.putModelConfig(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the PutShoppingList operation
 */
export const usePutShoppingList = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<PutShoppingListResponseContent, TError, PutShoppingListRequest>, 'mutationFn'>
): UseMutationResult<PutShoppingListResponseContent, TError, PutShoppingListRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: PutShoppingListRequest) => api.putShoppingList(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the PutUserExitLeaderboard operation
 */
export const usePutUserExitLeaderboard = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<PutUserExitLeaderboardResponseContent, TError, PutUserExitLeaderboardRequest>, 'mutationFn'>
): UseMutationResult<PutUserExitLeaderboardResponseContent, TError, PutUserExitLeaderboardRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: PutUserExitLeaderboardRequest) => api.putUserExitLeaderboard(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the PutUserJoinLeaderboard operation
 */
export const usePutUserJoinLeaderboard = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<PutUserJoinLeaderboardResponseContent, TError, PutUserJoinLeaderboardRequest>, 'mutationFn'>
): UseMutationResult<PutUserJoinLeaderboardResponseContent, TError, PutUserJoinLeaderboardRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: PutUserJoinLeaderboardRequest) => api.putUserJoinLeaderboard(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the PutUserProfile operation
 */
export const usePutUserProfile = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<PutUserProfileResponseContent, TError, PutUserProfileRequest>, 'mutationFn'>
): UseMutationResult<PutUserProfileResponseContent, TError, PutUserProfileRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: PutUserProfileRequest) => api.putUserProfile(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

