/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUserProfileResponseContent
 */
export interface GetUserProfileResponseContent {
    /**
     * 
     * @type {string}
     * @memberof GetUserProfileResponseContent
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserProfileResponseContent
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserProfileResponseContent
     */
    avatar?: string;
}


/**
 * Check if a given object implements the GetUserProfileResponseContent interface.
 */
export function instanceOfGetUserProfileResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;

    return isInstance;
}

export function GetUserProfileResponseContentFromJSON(json: any): GetUserProfileResponseContent {
    return GetUserProfileResponseContentFromJSONTyped(json, false);
}

export function GetUserProfileResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserProfileResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
    };
}

export function GetUserProfileResponseContentToJSON(value?: GetUserProfileResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'alias': value.alias,
        'avatar': value.avatar,
    };
}

