import {
  Box,
  Button,
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import LeaderboardTable from "./LeaderboardTable";
import { LeaderboardContext } from "../../layouts/App";

const LeaderboardPage: React.FC = () => {
  const { userLeaderboardInfo } = useContext(LeaderboardContext);
  const navigate = useNavigate();

  if (
    typeof userLeaderboardInfo.id === "string" &&
    userLeaderboardInfo.id.length === 0
  ) {
    return (
      <ContentLayout
        header={
          <SpaceBetween size="m">
            <Header
              variant="h1"
              description="This page shows the leaderboard standing of submitted models of an event"
              actions={
                <Button
                  variant="primary"
                  onClick={() => navigate(`/leaderboard/join`)}
                >
                  Join leaderboard
                </Button>
              }
            >
              Welcome to the Leaderboard Page!
            </Header>
            <Box variant="h2"></Box>
          </SpaceBetween>
        }
      >
        <Container>
          <SpaceBetween size="l">
            <Box variant="h1" textAlign="center">
              Join a leaderboard now to compete with the best Llamas!
            </Box>
            <img src="img/join-leaderboard-poster.png" width="100%"></img>
          </SpaceBetween>
        </Container>
      </ContentLayout>
    );
  } else {
    return <LeaderboardTable />;
  }
};

export default LeaderboardPage;
