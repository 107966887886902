/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LeaderboardSubmissionStructure } from './LeaderboardSubmissionStructure';
import {
    LeaderboardSubmissionStructureFromJSON,
    LeaderboardSubmissionStructureFromJSONTyped,
    LeaderboardSubmissionStructureToJSON,
} from './LeaderboardSubmissionStructure';

/**
 * 
 * @export
 * @interface GetLeaderboardSubmissionResponseContent
 */
export interface GetLeaderboardSubmissionResponseContent {
    /**
     * 
     * @type {Array<LeaderboardSubmissionStructure>}
     * @memberof GetLeaderboardSubmissionResponseContent
     */
    leaderboardSubmissionList: Array<LeaderboardSubmissionStructure>;
}


/**
 * Check if a given object implements the GetLeaderboardSubmissionResponseContent interface.
 */
export function instanceOfGetLeaderboardSubmissionResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "leaderboardSubmissionList" in value;

    return isInstance;
}

export function GetLeaderboardSubmissionResponseContentFromJSON(json: any): GetLeaderboardSubmissionResponseContent {
    return GetLeaderboardSubmissionResponseContentFromJSONTyped(json, false);
}

export function GetLeaderboardSubmissionResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLeaderboardSubmissionResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'leaderboardSubmissionList': ((json['leaderboardSubmissionList'] as Array<any>).map(LeaderboardSubmissionStructureFromJSON)),
    };
}

export function GetLeaderboardSubmissionResponseContentToJSON(value?: GetLeaderboardSubmissionResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'leaderboardSubmissionList': ((value.leaderboardSubmissionList as Array<any>).map(LeaderboardSubmissionStructureToJSON)),
    };
}

