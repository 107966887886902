/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FinaleStructure
 */
export interface FinaleStructure {
    /**
     * 
     * @type {string}
     * @memberof FinaleStructure
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof FinaleStructure
     */
    finaleId: string;
    /**
     * 
     * @type {string}
     * @memberof FinaleStructure
     */
    modelId: string;
    /**
     * 
     * @type {string}
     * @memberof FinaleStructure
     */
    modelSagemakerEndpoint: string;
    /**
     * 
     * @type {string}
     * @memberof FinaleStructure
     */
    configTemperature?: string;
    /**
     * 
     * @type {string}
     * @memberof FinaleStructure
     */
    configTopP?: string;
    /**
     * 
     * @type {string}
     * @memberof FinaleStructure
     */
    configTopK?: string;
    /**
     * 
     * @type {string}
     * @memberof FinaleStructure
     */
    teamName?: string;
}


/**
 * Check if a given object implements the FinaleStructure interface.
 */
export function instanceOfFinaleStructure(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "finaleId" in value;
    isInstance = isInstance && "modelId" in value;
    isInstance = isInstance && "modelSagemakerEndpoint" in value;

    return isInstance;
}

export function FinaleStructureFromJSON(json: any): FinaleStructure {
    return FinaleStructureFromJSONTyped(json, false);
}

export function FinaleStructureFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinaleStructure {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'finaleId': json['finaleId'],
        'modelId': json['modelId'],
        'modelSagemakerEndpoint': json['modelSagemakerEndpoint'],
        'configTemperature': !exists(json, 'config_temperature') ? undefined : json['config_temperature'],
        'configTopP': !exists(json, 'config_top_p') ? undefined : json['config_top_p'],
        'configTopK': !exists(json, 'config_top_k') ? undefined : json['config_top_k'],
        'teamName': !exists(json, 'teamName') ? undefined : json['teamName'],
    };
}

export function FinaleStructureToJSON(value?: FinaleStructure | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'finaleId': value.finaleId,
        'modelId': value.modelId,
        'modelSagemakerEndpoint': value.modelSagemakerEndpoint,
        'config_temperature': value.configTemperature,
        'config_top_p': value.configTopP,
        'config_top_k': value.configTopK,
        'teamName': value.teamName,
    };
}

