/*! Copyright [Amazon.com](http://amazon.com/), Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0 */
import * as React from "react";
import { Route, Routes as ReactRoutes } from "react-router-dom";
import ApiExplorer from "../../pages/ApiExplorer";
import Home from "../../pages/Home";
import LeaderboardPage from "../../pages/Leaderboard";
import JoinLeaderboardPage from "../../pages/Leaderboard/JoinLeaderboard";
import SubmitModelToLeaderboardPage from "../../pages/Leaderboard/SubmitModelToLeaderboard";
import ModelManagementPage from "../../pages/ModelManagement";
import RegisterModelPage from "../../pages/RegisterModel";
import ShoppingList from "../../pages/ShoppingList";
import ShoppingLists from "../../pages/ShoppingLists";
import TrainerProfilePage from "../../pages/TrainerProfile";
import ValidateTrainingData from "../../pages/ValidateTrainingData";
/**
 * Defines the Routes.
 */
const Routes: React.FC = () => {
  return (
    <ReactRoutes>
      <Route key={0} path="/models" element={<ModelManagementPage />} />
      <Route key={1} path="/:shoppingListId" element={<ShoppingList />} />
      <Route key={2} path="/apiExplorer" element={<ApiExplorer />} />
      <Route
        key={3}
        path="/leaderboard/join"
        element={<JoinLeaderboardPage />}
      />
      <Route key={4} path="/leaderboard" element={<LeaderboardPage />} />
      <Route
        key={5}
        path="/leaderboard/submit"
        element={<SubmitModelToLeaderboardPage />}
      />
      <Route path="/profile" element={<TrainerProfilePage />} />
      <Route
        path="/validate-training-data"
        element={<ValidateTrainingData />}
      />
      <Route path="/shoppinglist" element={<ShoppingLists />} />
      <Route path="/models/create" element={<RegisterModelPage />} />
      <Route path="/" element={<Home />} />
    </ReactRoutes>
  );
};

export default Routes;
