/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelResponseStructure
 */
export interface ModelResponseStructure {
    /**
     * 
     * @type {string}
     * @memberof ModelResponseStructure
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ModelResponseStructure
     */
    finaleId: string;
    /**
     * 
     * @type {string}
     * @memberof ModelResponseStructure
     */
    modelId: string;
    /**
     * 
     * @type {string}
     * @memberof ModelResponseStructure
     */
    modelSagemakerEndpoint: string;
    /**
     * 
     * @type {string}
     * @memberof ModelResponseStructure
     */
    modelResults?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelResponseStructure
     */
    teamName?: string;
}


/**
 * Check if a given object implements the ModelResponseStructure interface.
 */
export function instanceOfModelResponseStructure(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "finaleId" in value;
    isInstance = isInstance && "modelId" in value;
    isInstance = isInstance && "modelSagemakerEndpoint" in value;

    return isInstance;
}

export function ModelResponseStructureFromJSON(json: any): ModelResponseStructure {
    return ModelResponseStructureFromJSONTyped(json, false);
}

export function ModelResponseStructureFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelResponseStructure {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'finaleId': json['finaleId'],
        'modelId': json['modelId'],
        'modelSagemakerEndpoint': json['modelSagemakerEndpoint'],
        'modelResults': !exists(json, 'modelResults') ? undefined : json['modelResults'],
        'teamName': !exists(json, 'teamName') ? undefined : json['teamName'],
    };
}

export function ModelResponseStructureToJSON(value?: ModelResponseStructure | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'finaleId': value.finaleId,
        'modelId': value.modelId,
        'modelSagemakerEndpoint': value.modelSagemakerEndpoint,
        'modelResults': value.modelResults,
        'teamName': value.teamName,
    };
}

