/*! Copyright [Amazon.com](http://amazon.com/), Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0 */
import {
  Box,
  Container,
  ContentLayout,
  SpaceBetween,
} from "@cloudscape-design/components";

/**
 * Component to render the home "/" route.
 */
const Home: React.FC = () => {
  return (
    <ContentLayout>
      <Container>
        <SpaceBetween size="l" direction="vertical">
          <Box variant="h1" textAlign="center">
            Train your LLMs and compete!<br></br>
            Register your model and submit to an open leaderboard.
          </Box>
          <Box variant="h1" textAlign="center">
            <img style={{ maxWidth: "80%", width: "350px" }} src="logo.png" />
          </Box>
        </SpaceBetween>
      </Container>
    </ContentLayout>
  );
};

export default Home;
