/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelResponseStructure } from './ModelResponseStructure';
import {
    ModelResponseStructureFromJSON,
    ModelResponseStructureFromJSONTyped,
    ModelResponseStructureToJSON,
} from './ModelResponseStructure';

/**
 * 
 * @export
 * @interface GetModelResponseResponseContent
 */
export interface GetModelResponseResponseContent {
    /**
     * 
     * @type {Array<ModelResponseStructure>}
     * @memberof GetModelResponseResponseContent
     */
    finaleList: Array<ModelResponseStructure>;
}


/**
 * Check if a given object implements the GetModelResponseResponseContent interface.
 */
export function instanceOfGetModelResponseResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "finaleList" in value;

    return isInstance;
}

export function GetModelResponseResponseContentFromJSON(json: any): GetModelResponseResponseContent {
    return GetModelResponseResponseContentFromJSONTyped(json, false);
}

export function GetModelResponseResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetModelResponseResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'finaleList': ((json['finaleList'] as Array<any>).map(ModelResponseStructureFromJSON)),
    };
}

export function GetModelResponseResponseContentToJSON(value?: GetModelResponseResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'finaleList': ((value.finaleList as Array<any>).map(ModelResponseStructureToJSON)),
    };
}

