/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutLeaderboardSubmissionRequestContent
 */
export interface PutLeaderboardSubmissionRequestContent {
    /**
     * 
     * @type {string}
     * @memberof PutLeaderboardSubmissionRequestContent
     */
    leaderboardId: string;
    /**
     * 
     * @type {string}
     * @memberof PutLeaderboardSubmissionRequestContent
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof PutLeaderboardSubmissionRequestContent
     */
    modelId: string;
}


/**
 * Check if a given object implements the PutLeaderboardSubmissionRequestContent interface.
 */
export function instanceOfPutLeaderboardSubmissionRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "leaderboardId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "modelId" in value;

    return isInstance;
}

export function PutLeaderboardSubmissionRequestContentFromJSON(json: any): PutLeaderboardSubmissionRequestContent {
    return PutLeaderboardSubmissionRequestContentFromJSONTyped(json, false);
}

export function PutLeaderboardSubmissionRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutLeaderboardSubmissionRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'leaderboardId': json['leaderboardId'],
        'userId': json['userId'],
        'modelId': json['modelId'],
    };
}

export function PutLeaderboardSubmissionRequestContentToJSON(value?: PutLeaderboardSubmissionRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'leaderboardId': value.leaderboardId,
        'userId': value.userId,
        'modelId': value.modelId,
    };
}

