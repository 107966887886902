import {
  Box,
  Button,
  Container,
  ContentLayout,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  SpaceBetween,
  Tiles,
} from "@cloudscape-design/components";

import {
  useGetUserProfile,
  usePutUserProfile,
} from "myapi-typescript-react-query-hooks";

import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../layouts/App";

export const avatarMaps = [
  {
    label: "Knight Llama",
    image: (
      <img
        src="img/avatar1.jpg"
        alt="Knight Llama"
        width="100%"
        height="100%"
      />
    ),
    value: "avatar1",
  },
  {
    label: "Dorkey Llama",
    image: (
      <img
        src="img/avatar2.jpg"
        alt="Dorkey Llama"
        width="100%"
        height="100%"
      />
    ),
    value: "avatar2",
  },
  {
    label: "Friendly Llama",
    image: (
      <img
        src="img/avatar3.jpg"
        alt="Cutie Alpaca"
        width="100%"
        height="100%"
      />
    ),
    value: "avatar3",
  },
  {
    label: "Starlight Llama",
    image: (
      <img
        src="img/avatar4.png"
        alt="Starlight Llama"
        width="100%"
        height="100%"
      />
    ),
    value: "avatar4",
  },
  {
    label: "Master Llama",
    image: (
      <img
        src="img/avatar5.png"
        alt="Master Llama"
        width="100%"
        height="100%"
      />
    ),
    value: "avatar5",
  },
  {
    label: "Bubbly Llama",
    image: (
      <img
        src="img/avatar6.png"
        alt="Bubbly Llama"
        width="100%"
        height="100%"
      />
    ),
    value: "avatar6",
  },
  {
    label: "Merlin Llama",
    image: (
      <img
        src="img/avatar7.png"
        alt="Merlin Llama"
        width="100%"
        height="100%"
      />
    ),
    value: "avatar7",
  },
  {
    label: "Wizard Llama",
    image: (
      <img
        src="img/avatar8.png"
        alt="Wizard Llama"
        width="100%"
        height="100%"
      />
    ),
    value: "avatar8",
  },
  {
    label: "Ninja Llama",
    image: (
      <img src="img/avatar9.png" alt="Ninja Llama" width="100%" height="100%" />
    ),
    value: "avatar9",
  },
  {
    label: "Lieutenant Llama",
    image: (
      <img
        src="img/avatar10.png"
        alt="Lieutenant Llama"
        width="100%"
        height="100%"
      />
    ),
    value: "avatar10",
  },
];

const TrainerProfilePage: React.FC = () => {
  const [chooseAvatarVisible, setChooseAvatarVisible] = useState(false);
  const [editAliasVisible, setEditAliasVisible] = useState(false);
  const [inputAlias, setInputAlias] = useState("");
  const userInfo = useContext(UserContext);
  const [currentUserProfile, setCurrentUserProfile] = useState({
    userId: userInfo.userId,
    alias: "",
    avatar: "",
  });

  const [selectedAvatar, setSelectedAvatar] = useState({
    label: "",
    image: <img />,
    value: "",
  });
  const [onFocusAvatar, setOnFocusAvatar] = useState({
    label: "",
    image: <img />,
    value: "",
  });

  const putUserProfle = usePutUserProfile();
  const getUserProfile = useGetUserProfile(
    {
      userId: userInfo.userId,
    },
    { enabled: userInfo.userId != "" },
  );

  const editUserProfile = async (newProfile: any) => {
    await putUserProfle
      .mutateAsync({
        putUserProfileRequestContent: newProfile,
      })
      .then((data) => {
        console.log(data.userId);
      })
      .catch((err) => console.log("error: " + err));
  };

  useEffect(() => {
    if (userInfo.userId) {
      getUserProfile
        .refetch()
        .then((res) => {
          if (res.data) {
            setCurrentUserProfile({
              userId: userInfo.userId,
              alias: res.data.alias!,
              avatar: res.data.avatar!,
            });

            const currentAvatar = avatarMaps.find(
              (avatar) => avatar.value == res.data.avatar,
            );
            if (currentAvatar) {
              setSelectedAvatar(currentAvatar);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [userInfo.userId]);

  return (
    <ContentLayout>
      <Container header={<Header variant="h1">Your Trainer Profile</Header>}>
        <Box padding={{ top: "m" }}>
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 7 }]}>
            <SpaceBetween direction="vertical" size={"xs"}>
              <Box textAlign="center">{selectedAvatar.image}</Box>
              <Button
                fullWidth
                variant="primary"
                iconName="upload"
                onClick={() => setChooseAvatarVisible(true)}
              >
                Change avatar
              </Button>
            </SpaceBetween>

            <div>
              <SpaceBetween direction="vertical" size={"l"}>
                <Box padding={{ left: "l", bottom: "m" }}>
                  <Box fontSize="heading-m" fontWeight="heavy">
                    Username
                  </Box>
                  <Box fontSize="heading-s" padding={{ top: "xs" }}>
                    {userInfo.username}
                  </Box>
                </Box>
                <Box padding={{ left: "l" }}>
                  <Box fontSize="heading-m" fontWeight="heavy">
                    Alias
                  </Box>
                  <Box fontSize="heading-s" padding={{ top: "xs" }}>
                    <SpaceBetween
                      direction="horizontal"
                      size={"s"}
                      alignItems="center"
                    >
                      <div>{currentUserProfile.alias}</div>
                      <Button
                        iconName="edit"
                        onClick={() => {
                          setInputAlias(currentUserProfile.alias);
                          setEditAliasVisible(true);
                        }}
                      ></Button>
                    </SpaceBetween>
                  </Box>
                </Box>
              </SpaceBetween>
            </div>
          </Grid>
        </Box>
        <Modal
          onDismiss={() => setChooseAvatarVisible(false)}
          visible={chooseAvatarVisible}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={() => setChooseAvatarVisible(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    const newUserProfle = currentUserProfile;
                    newUserProfle.avatar = onFocusAvatar.value;
                    editUserProfile(newUserProfle).catch((err) =>
                      console.log("error: " + err),
                    );
                    setSelectedAvatar(onFocusAvatar);
                    setChooseAvatarVisible(false);
                  }}
                >
                  OK
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Choose your Avatar"
        >
          <Tiles
            onChange={({ detail }) => {
              const avatar =
                avatarMaps.find((ava) => ava.value == detail.value) ||
                avatarMaps[0];
              setOnFocusAvatar(avatar);
            }}
            value={onFocusAvatar.value}
            columns={2}
            items={avatarMaps}
          />
        </Modal>
        <Modal
          visible={editAliasVisible}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={() => setEditAliasVisible(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    const newUserProfle = currentUserProfile;
                    newUserProfle.alias = inputAlias;
                    editUserProfile(newUserProfle).catch((err) =>
                      console.log("error: " + err),
                    );
                    setEditAliasVisible(false);
                  }}
                >
                  OK
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Edit your alias"
        >
          <FormField label="Your new alias">
            <Input
              value={inputAlias}
              onChange={(event) => setInputAlias(event.detail.value)}
            />
          </FormField>
        </Modal>
      </Container>
    </ContentLayout>
  );
};

export default TrainerProfilePage;
