/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Box,
  Button,
  ContentLayout,
  Flashbar,
  Header,
  Link,
  Modal,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from "@cloudscape-design/components";
import {
  LeaderboardSubmissionStructure,
  useGetLeaderboardSubmission,
  useGetUserLeaderboard,
  PutUserExitLeaderboardRequestContent,
  usePutUserExitLeaderboard,
  useGetLeaderboard,
} from "myapi-typescript-react-query-hooks";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppLayoutContext,
  LeaderboardContext,
  UserContext,
} from "../../layouts/App";
import { avatarMaps } from "../TrainerProfile";
import "./LeaderboardTable.css";
import { LeaderboardHeaderInfo } from "./Models/LeaderboardInfoModel";

/**
 * Component to render the ShoppingLists "/" route.
 */

interface RankedLeaderboardSubmissionStructure
  extends LeaderboardSubmissionStructure {
  rank: number;
  gapToFirst: number;
}
const LeaderboardTable: React.FC = () => {
  const pageSize: number = 20;
  const { setAppLayoutProps } = useContext(AppLayoutContext);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>("");
  const [prevSearchText, setPrevSearchText] = useState<string>("");
  const [submittedModels, setSubmittedModels] = useState<
    RankedLeaderboardSubmissionStructure[]
  >([]);
  const [pagedSubmittedModels, setPagedSubmittedModels] = useState<
    RankedLeaderboardSubmissionStructure[]
  >([]);
  const [filteredSubmittedModels, setFilteredSubmittedModels] = useState<
    RankedLeaderboardSubmissionStructure[]
  >([]);
  const userInfo = useContext(UserContext);
  const { userLeaderboardInfo, setUserLeaderboardInfo } =
    useContext(LeaderboardContext);
  const getLeaderboardSubmission = useGetLeaderboardSubmission(
    {
      leaderboardId: userLeaderboardInfo.id,
      userId: userInfo.userId,
    },
    { enabled: userLeaderboardInfo.id != "" },
  );
  const getLeaderboard = useGetLeaderboard({
    leaderboardId: userLeaderboardInfo.id,
  });

  const getUserLeaderboard = useGetUserLeaderboard(
    {
      userId: userInfo.userId,
    },
    { enabled: userInfo.userId != "" },
  );
  const putUserExitLeaderboard = usePutUserExitLeaderboard();
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [currentLeaderboardInfo, setCurrentLeaderboardInfo] =
    useState<LeaderboardHeaderInfo>();

  useEffect(() => {
    setAppLayoutProps({
      contentType: "table",
    });

    if (
      typeof userLeaderboardInfo.id === "string" &&
      userLeaderboardInfo.id.length === 0
    ) {
      console.log("leaderboard id is empty");
    }

    getLeaderboard.refetch().then((res) => {
      setCurrentLeaderboardInfo({
        name: res.data?.leaderboardList[0].name,
        description: res.data?.leaderboardList[0].description,
      });
    });

    if (userInfo.userId && userLeaderboardInfo.id) {
      let counter = 1;
      getLeaderboardSubmission
        .refetch()
        .then((res) => {
          const queriedList = res.data?.leaderboardSubmissionList || [];
          const sortedList = queriedList.sort(
            (a, b) => b.winRate! - a.winRate!,
          );
          const firstPlaceWinRate = sortedList[0].winRate!;
          const rankedList = sortedList.map((model) => {
            const rankedModel: RankedLeaderboardSubmissionStructure = {
              ...model,
              rank: 0,
              gapToFirst: 0,
            };
            rankedModel.rank = counter;
            rankedModel.gapToFirst = firstPlaceWinRate - model.winRate!;
            counter = counter + 1;
            return rankedModel;
          });
          setSubmittedModels(rankedList);
          setIsLoading(res.isLoading);
        })
        .catch((err) => {
          console.log("error: " + err);
          setSubmittedModels([]);
          setIsLoading(false);
        });

      if (userLeaderboardInfo.pendingSubmitCompletion === false) {
        let intervalCounter = 0;
        const refetchInverval = setInterval(() => {
          intervalCounter++;
          getUserLeaderboard.refetch().then((res) => {
            console.log("!!", res);
            setUserLeaderboardInfo({
              ...userLeaderboardInfo,
              pendingSubmitCompletion: res.data?.pendingSubmitCompletion!,
              statusMessage: res.data?.statusMessage!,
            });
          });
          if (intervalCounter === 5) {
            clearInterval(refetchInverval);
          }
        }, 1000);
      } else {
        setTimeout(() => {
          getUserLeaderboard.refetch().then((res) => {
            setUserLeaderboardInfo({
              ...userLeaderboardInfo,
              pendingSubmitCompletion: res.data?.pendingSubmitCompletion!,
              statusMessage: res.data?.statusMessage!,
            });
            console.log(res.data?.pendingSubmitCompletion);
          });
        }, 5000);
      }
    }
  }, [setAppLayoutProps, userInfo.userId, userLeaderboardInfo.id]);

  useEffect(() => {
    const _searchText = searchText?.toLowerCase().trim();
    let filtered = [...submittedModels];
    if (!filteredSubmittedModels) {
      setFilteredSubmittedModels(filtered);
    }

    if (prevSearchText != _searchText) {
      setPrevSearchText(_searchText);
      setTimeout(() => {
        setCurrentPage(1);
      }, 300);
    }
    filtered = submittedModels.filter(
      (item) =>
        item.alias?.toLowerCase().trim().includes(_searchText) ||
        item.modelId?.toLowerCase().trim().includes(_searchText),
    );
    setFilteredSubmittedModels(filtered);

    setPagedSubmittedModels(
      filtered.slice(
        currentPage == 1 ? 0 : (currentPage - 1) * pageSize,
        currentPage * pageSize,
      ),
    );
  }, [searchText, submittedModels, currentPage]);

  const leaveLeaderboard = async () => {
    const modelToSubmit: PutUserExitLeaderboardRequestContent = {
      userId: userInfo.userId,
    };
    await putUserExitLeaderboard
      .mutateAsync({
        putUserExitLeaderboardRequestContent: modelToSubmit,
      })
      .then((data) => {
        console.log(data);
        window.location.reload();
      })
      .catch((err) => console.log("error: " + err));
  };

  return (
    <>
      <ContentLayout
        header={
          <SpaceBetween size="m">
            <Header
              variant="h1"
              info={<Link>Info</Link>}
              description={
                <>
                  {currentLeaderboardInfo?.description} <br />
                  Total Trainers: {submittedModels.length}
                </>
              }
              actions={
                <SpaceBetween size="m" direction="horizontal">
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/leaderboard/submit`)}
                    disabled={userLeaderboardInfo.pendingSubmitCompletion}
                  >
                    + Submit your model
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => setShowConfirmationModal(true)}
                    data-className="red-btn"
                  >
                    Leave leaderboard
                  </Button>
                </SpaceBetween>
              }
            >
              {currentLeaderboardInfo?.name}
            </Header>
            <div>
              {userLeaderboardInfo.pendingSubmitCompletion ? (
                <Flashbar
                  items={[
                    {
                      type: "success",
                      loading: true,
                      content:
                        "Your model is submitted, please wait for assessment in the leaderboard. Revisit or refresh this page after 15~ minutes.",
                      dismissible: false,
                      id: "pending_submission_leaderboard",
                    },
                  ]}
                />
              ) : (
                <Flashbar
                  items={[
                    {
                      type: "info",
                      content:
                        "You have no pending model submission. To evaluate a new model to the leaderboard, submit your new model again.",
                      dismissible: false,
                      id: "no_pending_submission_leaderboard",
                    },
                  ]}
                />
              )}
            </div>
            <div>
              {userLeaderboardInfo.statusMessage ? (
                <Flashbar
                  items={[
                    {
                      type: "error",
                      content: userLeaderboardInfo.statusMessage,
                      dismissible: false,
                      id: "no_pending_submission_leaderboard",
                    },
                  ]}
                />
              ) : null}
            </div>
          </SpaceBetween>
        }
      >
        <Table
          loading={isLoading}
          loadingText="Loading models"
          wrapLines
          columnDefinitions={[
            {
              id: "rank",
              header: "Rank",
              cell: (e) => {
                return <h4>{e.rank}</h4>;
              },
              sortingField: "rank",
              isRowHeader: true,
            },
            {
              id: "team",
              header: "Trainer Alias",
              cell: (e: any) => {
                const userAvatar = avatarMaps.find(
                  (ava) => ava.value === e.avatar,
                );
                if (e.alias == "") {
                  e.alias = e.userId;
                }
                return (
                  <div className="col-trainer-alias">
                    <div>{userAvatar?.image}</div>
                    <h4>{e.alias}</h4>
                  </div>
                );
              },
            },
            {
              id: "model",
              header: "Best Model ID",
              cell: (e) => {
                return <h4>{e.modelId}</h4>;
              },
            },
            {
              id: "winRate",
              header: "Best Win Rate",
              cell: (e) => {
                return <h4>{e.winRate + "%"}</h4>;
              },
            },
            {
              id: "gapToFirst",
              header: "Gap to First",
              cell: (e) => {
                return <h4>{e.gapToFirst + "%"}</h4>;
              },
            },
            {
              id: "lastSubmittedWinRate",
              header: "Last Submitted Win Rate",
              cell: (e) => {
                return <h4>{e.lastSubmittedWinRate + "%"}</h4>;
              },
            },
            {
              id: "nWins",
              header: "No. of Wins",
              cell: (e) => {
                return <h4>{e.nWins}</h4>;
              },
            },
            // {
            //   id: "nDraws",
            //   header: "No. of Draws",
            //   cell: (e) => {
            //     return <h4>{e.nDraws}</h4>;
            //   },
            // },
            {
              id: "nTotal",
              header: "No. of Total Runs",
              cell: (e) => {
                return <h4>{e.nTotal}</h4>;
              },
            },
            {
              id: "totalSubmissions",
              header: "Total Submissions",
              cell: (e) => {
                return <h4>{e.totalSubmissions}</h4>;
              },
            },
          ]}
          // TODO: Check if can use memoize
          items={pagedSubmittedModels}
          trackBy="userId"
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>No submission yet</b>
                <Button onClick={() => navigate("/leaderboard/submit")}>
                  Submit your model
                </Button>
              </SpaceBetween>
            </Box>
          }
          filter={
            <TextFilter
              filteringPlaceholder="Find resources"
              filteringText={searchText}
              onChange={({ detail }) => setSearchText(detail.filteringText)}
            />
          }
          //TODO: Fixed pagination
          pagination={
            <Pagination
              currentPageIndex={currentPage}
              pagesCount={Math.ceil(
                (filteredSubmittedModels.length > 0
                  ? filteredSubmittedModels.length
                  : submittedModels.length) / pageSize,
              )}
              onChange={({ detail }) => setCurrentPage(detail.currentPageIndex)}
            />
          }
          //TODO: Confirm if this is needed or not - if needed, edit accordingly
          // preferences={
          //   <CollectionPreferences
          //     title="Preferences"
          //     confirmLabel="Confirm"
          //     cancelLabel="Cancel"
          //     preferences={{
          //       pageSize: pageSize,
          //       contentDisplay: [
          //         { id: "variable", visible: true },
          //         { id: "value", visible: true },
          //         { id: "type", visible: true },
          //         { id: "description", visible: true },
          //       ],
          //     }}
          //     pageSizePreference={{
          //       title: "Page size",
          //       options: [
          //         { value: 10, label: "10 resources" },
          //         { value: 20, label: "20 resources" },
          //       ],
          //     }}
          //     wrapLinesPreference={{}}
          //     stripedRowsPreference={{}}
          //     contentDensityPreference={{}}
          //     contentDisplayPreference={{
          //       options: [
          //         {
          //           id: "variable",
          //           label: "Variable name",
          //           alwaysVisible: true,
          //         },
          //         { id: "value", label: "Text value" },
          //         { id: "type", label: "Type" },
          //         { id: "description", label: "Description" },
          //       ],
          //     }}
          //     stickyColumnsPreference={{
          //       firstColumns: {
          //         title: "Stick first column(s)",
          //         description:
          //           "Keep the first column(s) visible while horizontally scrolling the table content.",
          //         options: [
          //           { label: "None", value: 0 },
          //           { label: "First column", value: 1 },
          //           { label: "First two columns", value: 2 },
          //         ],
          //       },
          //       lastColumns: {
          //         title: "Stick last column",
          //         description:
          //           "Keep the last column visible while horizontally scrolling the table content.",
          //         options: [
          //           { label: "None", value: 0 },
          //           { label: "Last column", value: 1 },
          //         ],
          //       },
          //     }}
          //   />
          // }
        />
        <Modal
          onDismiss={() => setShowConfirmationModal(false)}
          visible={showConfirmationModal}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link">Cancel</Button>
                <Button variant="primary" onClick={() => leaveLeaderboard()}>
                  Ok
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Confirmation"
        >
          Are you sure you want to leave the leaderboard?
        </Modal>
      </ContentLayout>
    </>
  );
};

export default LeaderboardTable;
