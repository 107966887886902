/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Box,
  Button,
  Header,
  Modal,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from "@cloudscape-design/components";
import {
  ModelStructure,
  useGetModelList,
  useDeleteModel,
} from "myapi-typescript-react-query-hooks";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../layouts/App";

const ModelTable: React.FC = () => {
  const pageSize = 10;
  const navigate = useNavigate();
  const userInfo = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedItems, setSelectedItems] = useState<ModelStructure[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [prevSearchText, setPrevSearchText] = useState<string>("");
  const [registeredModels, setRegisteredModels] = useState<ModelStructure[]>(
    [],
  );
  const [pagedRegisteredModels, setPagedRegisteredModels] = useState<
    ModelStructure[]
  >([]);
  const [filteredRegisteredModels, setFilteredRegisteredModels] = useState<
    ModelStructure[]
  >([]);
  const getModels = useGetModelList(
    { userId: userInfo.userId },
    { enabled: userInfo.userId != "" },
  );
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const deleteModel = useDeleteModel();

  useEffect(() => {
    if (userInfo.userId != "") {
      fetchModels();
    }
  }, [userInfo.userId]);

  const fetchModels = () => {
    getModels
      .refetch()
      .then((res) => {
        const queriedModelList = res.data?.modelList || [];
        setRegisteredModels(queriedModelList);
        setIsLoading(res.isLoading);
      })
      .catch((err) => console.log("error: " + err));
  };

  useEffect(() => {
    const _searchText = searchText?.toLowerCase().trim();
    let filtered = [...registeredModels];
    if (!filteredRegisteredModels) {
      setFilteredRegisteredModels(filtered);
    }

    if (prevSearchText != _searchText) {
      setPrevSearchText(_searchText);
      setTimeout(() => {
        setCurrentPage(1);
      }, 500);
    }
    filtered = registeredModels.filter(
      (item) =>
        item.name?.toLowerCase().trim().includes(_searchText) ||
        item.trainingJobName?.toLowerCase().trim().includes(_searchText) ||
        item.modelId?.toLowerCase().trim().includes(_searchText),
    );
    setFilteredRegisteredModels(filtered);

    setPagedRegisteredModels(
      filtered.slice(
        currentPage == 1 ? 0 : (currentPage - 1) * pageSize,
        currentPage * pageSize,
      ),
    );
  }, [registeredModels, currentPage, searchText]);

  const triggerDeleteModel = async () => {
    console.log(selectedItems[0]);
    if (selectedItems.length > 0 && selectedItems[0].modelId) {
      await deleteModel.mutateAsync({
        modelId: selectedItems[0].modelId,
        userId: selectedItems[0].userId,
      });
      setShowConfirmationModal(false);
      fetchModels();
    }
  };

  return (
    <>
      <Table
        header={
          <Header variant="h2" counter={`(${registeredModels.length})`}>
            Trained Models
          </Header>
        }
        onSelectionChange={({ detail }) =>
          setSelectedItems(detail.selectedItems)
        }
        selectedItems={selectedItems}
        loading={isLoading}
        loadingText="Loading models"
        columnDefinitions={[
          {
            id: "modelName",
            header: "Model name",
            cell: (e) => e.name,
            sortingField: "name",
            isRowHeader: true,
          },
          {
            id: "modelOutputs",
            header: "Model Outputs",
            cell: (e) => e.modelOutputs,
          },
          {
            id: "modelId",
            header: "Model Id",
            cell: (e) => e.modelId,
          },
          {
            id: "trainingJobName",
            header: "Training Job Name",
            cell: (e) => e.trainingJobName,
          },
        ]}
        columnDisplay={[
          { id: "modelName", visible: true },
          { id: "trainingJobName", visible: true },
          { id: "modelId", visible: true },
          { id: "modelOutputs", visible: false },
        ]}
        // TODO: Check if can use memoize
        items={pagedRegisteredModels}
        selectionType="single"
        trackBy="modelId"
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No models registered {registeredModels[0]?.name}</b>
              <Button onClick={() => navigate("/models/create")}>
                Register Model
              </Button>
            </SpaceBetween>
          </Box>
        }
        filter={
          <TextFilter
            filteringPlaceholder="Find resources"
            filteringText={searchText}
            onChange={({ detail }) => setSearchText(detail.filteringText)}
          />
        }
        //TODO: Fixed pagination
        pagination={
          <SpaceBetween size="xs" direction="horizontal">
            <Pagination
              currentPageIndex={currentPage}
              pagesCount={Math.ceil(
                (filteredRegisteredModels.length > 0
                  ? filteredRegisteredModels.length
                  : registeredModels.length) / pageSize,
              )}
              onChange={({ detail }) => setCurrentPage(detail.currentPageIndex)}
            />
            <Button
              variant="primary"
              data-className="red-btn"
              iconName="remove"
              onClick={() => setShowConfirmationModal(true)}
            >
              Delete
            </Button>
          </SpaceBetween>
        }
        //TODO: Confirm if this is needed or not - if needed, edit accordingly
        // preferences={
        //   <CollectionPreferences
        //     title="Preferences"
        //     confirmLabel="Confirm"
        //     cancelLabel="Cancel"
        //     preferences={{
        //       pageSize: 10,
        //       contentDisplay: [
        //         { id: "variable", visible: true },
        //         { id: "value", visible: true },
        //         { id: "type", visible: true },
        //         { id: "description", visible: true },
        //       ],
        //     }}
        //     pageSizePreference={{
        //       title: "Page size",
        //       options: [
        //         { value: 10, label: "10 resources" },
        //         { value: 20, label: "20 resources" },
        //       ],
        //     }}
        //     wrapLinesPreference={{}}
        //     stripedRowsPreference={{}}
        //     contentDensityPreference={{}}
        //     contentDisplayPreference={{
        //       options: [
        //         {
        //           id: "variable",
        //           label: "Variable name",
        //           alwaysVisible: true,
        //         },
        //         { id: "value", label: "Text value" },
        //         { id: "type", label: "Type" },
        //         { id: "description", label: "Description" },
        //       ],
        //     }}
        //     stickyColumnsPreference={{
        //       firstColumns: {
        //         title: "Stick first column(s)",
        //         description:
        //           "Keep the first column(s) visible while horizontally scrolling the table content.",
        //         options: [
        //           { label: "None", value: 0 },
        //           { label: "First column", value: 1 },
        //           { label: "First two columns", value: 2 },
        //         ],
        //       },
        //       lastColumns: {
        //         title: "Stick last column",
        //         description:
        //           "Keep the last column visible while horizontally scrolling the table content.",
        //         options: [
        //           { label: "None", value: 0 },
        //           { label: "Last column", value: 1 },
        //         ],
        //       },
        //     }}
        //   />
        // }
      />
      <Modal
        onDismiss={() => setShowConfirmationModal(false)}
        visible={showConfirmationModal}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link">Cancel</Button>
              <Button variant="primary" onClick={() => triggerDeleteModel()}>
                Ok
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Confirmation"
      >
        Are you sure you want to delete this model?
      </Modal>
    </>
  );
};

export default ModelTable;
