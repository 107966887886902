/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUserLeaderboardResponseContent
 */
export interface GetUserLeaderboardResponseContent {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserLeaderboardResponseContent
     */
    leaderboardList: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserLeaderboardResponseContent
     */
    pendingSubmitCompletion?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserLeaderboardResponseContent
     */
    statusMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLeaderboardResponseContent
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserLeaderboardResponseContent
     */
    avatar?: string;
}


/**
 * Check if a given object implements the GetUserLeaderboardResponseContent interface.
 */
export function instanceOfGetUserLeaderboardResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "leaderboardList" in value;

    return isInstance;
}

export function GetUserLeaderboardResponseContentFromJSON(json: any): GetUserLeaderboardResponseContent {
    return GetUserLeaderboardResponseContentFromJSONTyped(json, false);
}

export function GetUserLeaderboardResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserLeaderboardResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'leaderboardList': json['leaderboardList'],
        'pendingSubmitCompletion': !exists(json, 'pendingSubmitCompletion') ? undefined : json['pendingSubmitCompletion'],
        'statusMessage': !exists(json, 'statusMessage') ? undefined : json['statusMessage'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
    };
}

export function GetUserLeaderboardResponseContentToJSON(value?: GetUserLeaderboardResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'leaderboardList': value.leaderboardList,
        'pendingSubmitCompletion': value.pendingSubmitCompletion,
        'statusMessage': value.statusMessage,
        'alias': value.alias,
        'avatar': value.avatar,
    };
}

