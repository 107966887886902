import {
  Box,
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  Form,
  FormField,
  Header,
  Select,
  SelectProps,
  SpaceBetween,
  Wizard,
  WizardProps,
} from "@cloudscape-design/components";
import {
  ModelStructure,
  PutLeaderboardSubmissionRequestContent,
  useGetModelList,
  usePutLeaderboardSubmission,
} from "myapi-typescript-react-query-hooks";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LeaderboardContext, UserContext } from "../../layouts/App";

const SubmitModelToLeaderboardPage: React.FC = () => {
  const userInfo = useContext(UserContext);
  const { userLeaderboardInfo, setUserLeaderboardInfo } =
    useContext(LeaderboardContext);
  const [modelsForUser, setModelsForUser] = useState<SelectProps.Option[]>([]);
  const [selectedModel, setSelectedModel] = useState<SelectProps.Option>();
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const putLeaderboardSubmission = usePutLeaderboardSubmission();
  const getModelsForUser = useGetModelList(
    { userId: userInfo.userId },
    { enabled: userInfo.userId != "" },
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo.userId) {
      getModelsForUser
        .refetch()
        .then((res) => {
          console.log(res);
          const queriedList: Array<ModelStructure> = res.data?.modelList || [];
          const dropdownOptions: Array<SelectProps.Option> = [];
          queriedList.map((model: ModelStructure) => {
            dropdownOptions.push({
              value: model.modelId || "",
              label: model.name + " --- " + model.trainingJobName,
            });
          });
          setModelsForUser(dropdownOptions);
        })
        .catch((err) => console.log("error: " + err));
    }
  }, [userInfo.userId]);

  const handleDropdrownOptionChange = (event: any) => {
    setSelectedModel(event.detail.selectedOption);
  };

  const i18nStrings = {
    stepNumberLabel: (stepNumber: number) => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber: number, stepsCount: number) =>
      `Step ${stepNumber} of ${stepsCount}`,
    skipToButtonLabel: (step: { title: string }, _stepNumber: number) =>
      `Skip to ${step.title}`,
    navigationAriaLabel: "Steps",
    cancelButton: "Cancel",
    previousButton: "Previous",
    nextButton: "Next",
    optional: "optional",
  };

  const steps = [
    {
      title: "Leaderboard Submission",
      content: (
        <>
          <SpaceBetween direction="vertical" size="l">
            <Container
              header={
                <Header
                  variant="h1"
                  description="Choose your model to submit to leaderboard"
                >
                  Choose your model
                </Header>
              }
            >
              <FormField>
                <Select
                  selectedOption={selectedModel ? selectedModel : null}
                  placeholder="Choose model to submit"
                  onChange={handleDropdrownOptionChange}
                  loadingText="Fetching models"
                  empty="No models registered"
                  options={modelsForUser}
                />
              </FormField>
            </Container>
          </SpaceBetween>
        </>
      ),
      isOptional: false,
    },
    {
      title: "Review and submit",
      content: (
        <SpaceBetween size="xs">
          <Header
            variant="h2"
            actions={
              <Button onClick={() => setActiveStepIndex(0)}>Edit</Button>
            }
          >
            Submit Model to Leaderboard
          </Header>
          <Container>
            <ColumnLayout variant="text-grid">
              <div>
                <Box variant="awsui-key-label">Your model</Box>
                <div>{selectedModel?.label}</div>
              </div>
            </ColumnLayout>
          </Container>
        </SpaceBetween>
      ),
    },
  ];
  const validateThenNavigateRegisterModelForm = (
    detail: WizardProps.NavigateDetail,
  ) => {
    console.log(detail.requestedStepIndex);
    if (activeStepIndex != 0) {
      console.log("set index to 1");
      setActiveStepIndex(detail.requestedStepIndex);
    } else {
      setActiveStepIndex(detail.requestedStepIndex);
    }
  };

  async function handleSubmitModelToLeaderboard() {
    const modelToSubmit: PutLeaderboardSubmissionRequestContent = {
      leaderboardId: userLeaderboardInfo.id,
      userId: userInfo.userId,
      modelId: selectedModel?.value!,
    };
    await putLeaderboardSubmission
      .mutateAsync({
        putLeaderboardSubmissionRequestContent: modelToSubmit,
      })
      .then((data) => {
        const jobId = data.jobId;
        console.log(jobId);
      })
      .catch((err) => console.log("error: " + err));

    setUserLeaderboardInfo({
      ...userLeaderboardInfo,
      pendingSubmitCompletion: true,
    });
    navigate(`/leaderboard`);
  }

  return (
    <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header variant="h1">Model Registration</Header>
        </SpaceBetween>
      }
    >
      <Form>
        <Wizard
          i18nStrings={i18nStrings}
          submitButtonText="Submit"
          onNavigate={({ detail }) => {
            validateThenNavigateRegisterModelForm(detail);
          }}
          onSubmit={handleSubmitModelToLeaderboard}
          onCancel={() => navigate("/leaderboard")}
          activeStepIndex={activeStepIndex}
          allowSkipTo
          steps={steps}
        />
      </Form>
    </ContentLayout>
  );
};

export default SubmitModelToLeaderboardPage;
