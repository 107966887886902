/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelRankingStructure
 */
export interface ModelRankingStructure {
    /**
     * 
     * @type {string}
     * @memberof ModelRankingStructure
     */
    modelId?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelRankingStructure
     */
    rank?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelRankingStructure
     */
    teamName?: string;
}


/**
 * Check if a given object implements the ModelRankingStructure interface.
 */
export function instanceOfModelRankingStructure(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelRankingStructureFromJSON(json: any): ModelRankingStructure {
    return ModelRankingStructureFromJSONTyped(json, false);
}

export function ModelRankingStructureFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelRankingStructure {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'teamName': !exists(json, 'teamName') ? undefined : json['teamName'],
    };
}

export function ModelRankingStructureToJSON(value?: ModelRankingStructure | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelId': value.modelId,
        'rank': value.rank,
        'teamName': value.teamName,
    };
}

