/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Button,
  ContentLayout,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ModelTable from "./ModelTable";
import { AppLayoutContext } from "../../layouts/App";

/**
 * Component to render the ShoppingLists "/" route.
 */
const ModelManagementPage: React.FC = () => {
  const { setAppLayoutProps } = useContext(AppLayoutContext);
  const navigate = useNavigate();

  useEffect(() => {
    setAppLayoutProps({
      contentType: "table",
    });
  }, [setAppLayoutProps]);

  return (
    <>
      <ContentLayout
        header={
          <Header
            variant="h1"
            description="Your registered fine-tuned LLM models"
            actions={
              <SpaceBetween direction="horizontal" size="m">
                <Button
                  variant="primary"
                  onClick={() => navigate(`/models/create`)}
                >
                  + Register New Model
                </Button>
              </SpaceBetween>
            }
          >
            Model Management
          </Header>
        }
      >
        <ModelTable />
      </ContentLayout>
    </>
  );
};

export default ModelManagementPage;
