/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutModelConfigRequestContent
 */
export interface PutModelConfigRequestContent {
    /**
     * 
     * @type {string}
     * @memberof PutModelConfigRequestContent
     */
    finaleId: string;
    /**
     * 
     * @type {string}
     * @memberof PutModelConfigRequestContent
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof PutModelConfigRequestContent
     */
    configTemperature: string;
    /**
     * 
     * @type {string}
     * @memberof PutModelConfigRequestContent
     */
    configTopK?: string;
    /**
     * 
     * @type {string}
     * @memberof PutModelConfigRequestContent
     */
    configTopP?: string;
}


/**
 * Check if a given object implements the PutModelConfigRequestContent interface.
 */
export function instanceOfPutModelConfigRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "finaleId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "configTemperature" in value;

    return isInstance;
}

export function PutModelConfigRequestContentFromJSON(json: any): PutModelConfigRequestContent {
    return PutModelConfigRequestContentFromJSONTyped(json, false);
}

export function PutModelConfigRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutModelConfigRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'finaleId': json['finaleId'],
        'userId': json['userId'],
        'configTemperature': json['config_temperature'],
        'configTopK': !exists(json, 'config_top_k') ? undefined : json['config_top_k'],
        'configTopP': !exists(json, 'config_top_p') ? undefined : json['config_top_p'],
    };
}

export function PutModelConfigRequestContentToJSON(value?: PutModelConfigRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'finaleId': value.finaleId,
        'userId': value.userId,
        'config_temperature': value.configTemperature,
        'config_top_k': value.configTopK,
        'config_top_p': value.configTopP,
    };
}

