import {
  Box,
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Wizard,
  WizardProps,
} from "@cloudscape-design/components";
import {
  PutUserJoinLeaderboardRequestContent,
  usePutUserJoinLeaderboard,
} from "myapi-typescript-react-query-hooks";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LeaderboardContext, UserContext } from "../../layouts/App";

const JoinLeaderboardPage: React.FC = () => {
  const [eventId, setEventId] = useState<string>("");
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const putUserJoinLeaderboard = usePutUserJoinLeaderboard();
  const userInfo = useContext(UserContext);
  const { userLeaderboardInfo, setUserLeaderboardInfo } =
    useContext(LeaderboardContext);
  const navigate = useNavigate();

  const i18nStrings = {
    stepNumberLabel: (stepNumber: number) => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber: number, stepsCount: number) =>
      `Step ${stepNumber} of ${stepsCount}`,
    skipToButtonLabel: (step: { title: string }, _stepNumber: number) =>
      `Skip to ${step.title}`,
    navigationAriaLabel: "Steps",
    cancelButton: "Cancel",
    previousButton: "Previous",
    nextButton: "Next",
    optional: "optional",
  };

  const steps = [
    {
      title: "Join leaderboard",
      content: (
        <>
          <SpaceBetween direction="vertical" size="l">
            <Container
              header={
                <Header
                  variant="h1"
                  description="Enter Event ID given by your event host"
                >
                  Enter Event ID to join leaderboard
                </Header>
              }
            >
              <FormField>
                <Input
                  placeholder="e.g 1234-xdsf-12ka-hi21"
                  value={eventId}
                  onChange={(event) => {
                    // if (eventId) {
                    //   setModelNameErrorMsg("");
                    // }
                    console.log(event.detail.value);
                    setEventId(event.detail.value);
                  }}
                />
              </FormField>
            </Container>
          </SpaceBetween>
        </>
      ),
      isOptional: false,
    },
    {
      title: "Review and join",
      content: (
        <SpaceBetween size="xs">
          <Header
            variant="h2"
            actions={
              <Button onClick={() => setActiveStepIndex(0)}>Edit</Button>
            }
          >
            Join leaderboard
          </Header>
          <Container>
            <ColumnLayout variant="text-grid">
              <div>
                <Box variant="awsui-key-label">Event ID</Box>
                <div>{eventId}</div>
              </div>
            </ColumnLayout>
          </Container>
        </SpaceBetween>
      ),
    },
  ];
  const validateThenNavigateRegisterModelForm = (
    detail: WizardProps.NavigateDetail,
  ) => {
    if (activeStepIndex != 0) {
      setActiveStepIndex(detail.requestedStepIndex);
    } else {
      if (eventId) {
        setActiveStepIndex(detail.requestedStepIndex);
      }

      //TODO: Handle invalid event ID
      // if (!modelName) {
      //   setModelNameErrorMsg("Please provide a model name");
      // }
    }
  };

  async function handleJoinLeaderboard() {
    const newUserJoinLeaderboard: PutUserJoinLeaderboardRequestContent = {
      leaderboardId: eventId,
      userId: userInfo.userId,
    };
    await putUserJoinLeaderboard
      .mutateAsync({
        putUserJoinLeaderboardRequestContent: newUserJoinLeaderboard,
      })
      .then((data) => {
        const leaderboardId = data.leaderboardId;
        setUserLeaderboardInfo({ ...userLeaderboardInfo, id: leaderboardId });
        navigate(`/leaderboard`);
      });
  }

  return (
    <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header variant="h1">Model Registration</Header>
        </SpaceBetween>
      }
    >
      <Form>
        <Wizard
          i18nStrings={i18nStrings}
          submitButtonText="Join League"
          onNavigate={({ detail }) => {
            validateThenNavigateRegisterModelForm(detail);
          }}
          onSubmit={handleJoinLeaderboard}
          onCancel={() => navigate("/leaderboard")}
          activeStepIndex={activeStepIndex}
          allowSkipTo
          steps={steps}
        />
      </Form>
    </ContentLayout>
  );
};

export default JoinLeaderboardPage;
