/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeaderboardSubmissionStructure
 */
export interface LeaderboardSubmissionStructure {
    /**
     * 
     * @type {string}
     * @memberof LeaderboardSubmissionStructure
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardSubmissionStructure
     */
    modelId: string;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardSubmissionStructure
     */
    jobId?: string;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardSubmissionStructure
     */
    winRate?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardSubmissionStructure
     */
    lastSubmittedWinRate?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardSubmissionStructure
     */
    standardError?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardSubmissionStructure
     */
    nDraws?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardSubmissionStructure
     */
    nTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardSubmissionStructure
     */
    nWins?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardSubmissionStructure
     */
    nWinsBase?: number;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardSubmissionStructure
     */
    jobStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardSubmissionStructure
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardSubmissionStructure
     */
    avatar?: string;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardSubmissionStructure
     */
    totalSubmissions?: number;
}


/**
 * Check if a given object implements the LeaderboardSubmissionStructure interface.
 */
export function instanceOfLeaderboardSubmissionStructure(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "modelId" in value;

    return isInstance;
}

export function LeaderboardSubmissionStructureFromJSON(json: any): LeaderboardSubmissionStructure {
    return LeaderboardSubmissionStructureFromJSONTyped(json, false);
}

export function LeaderboardSubmissionStructureFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardSubmissionStructure {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'modelId': json['modelId'],
        'jobId': !exists(json, 'jobId') ? undefined : json['jobId'],
        'winRate': !exists(json, 'winRate') ? undefined : json['winRate'],
        'lastSubmittedWinRate': !exists(json, 'lastSubmittedWinRate') ? undefined : json['lastSubmittedWinRate'],
        'standardError': !exists(json, 'standardError') ? undefined : json['standardError'],
        'nDraws': !exists(json, 'nDraws') ? undefined : json['nDraws'],
        'nTotal': !exists(json, 'nTotal') ? undefined : json['nTotal'],
        'nWins': !exists(json, 'nWins') ? undefined : json['nWins'],
        'nWinsBase': !exists(json, 'nWinsBase') ? undefined : json['nWinsBase'],
        'jobStatus': !exists(json, 'jobStatus') ? undefined : json['jobStatus'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
        'totalSubmissions': !exists(json, 'totalSubmissions') ? undefined : json['totalSubmissions'],
    };
}

export function LeaderboardSubmissionStructureToJSON(value?: LeaderboardSubmissionStructure | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'modelId': value.modelId,
        'jobId': value.jobId,
        'winRate': value.winRate,
        'lastSubmittedWinRate': value.lastSubmittedWinRate,
        'standardError': value.standardError,
        'nDraws': value.nDraws,
        'nTotal': value.nTotal,
        'nWins': value.nWins,
        'nWinsBase': value.nWinsBase,
        'jobStatus': value.jobStatus,
        'alias': value.alias,
        'avatar': value.avatar,
        'totalSubmissions': value.totalSubmissions,
    };
}

