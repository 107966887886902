/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequestErrorResponseContent,
  DeleteLeaderboardResponseContent,
  DeleteModelResponseContent,
  DeleteShoppingListResponseContent,
  GetLeaderboardResponseContent,
  GetLeaderboardSubmissionResponseContent,
  GetModelConfigResponseResponseContent,
  GetModelListResponseContent,
  GetModelRankingResponseContent,
  GetModelResponseResponseContent,
  GetShoppingListsResponseContent,
  GetUserLeaderboardResponseContent,
  GetUserProfileResponseContent,
  InternalFailureErrorResponseContent,
  NotAuthorizedErrorResponseContent,
  NotFoundErrorResponseContent,
  PutLeaderboardRequestContent,
  PutLeaderboardResponseContent,
  PutLeaderboardSubmissionRequestContent,
  PutLeaderboardSubmissionResponseContent,
  PutModelConfigRequestContent,
  PutModelConfigResponseContent,
  PutModelRequestContent,
  PutModelResponseContent,
  PutShoppingListRequestContent,
  PutShoppingListResponseContent,
  PutUserExitLeaderboardRequestContent,
  PutUserExitLeaderboardResponseContent,
  PutUserJoinLeaderboardRequestContent,
  PutUserJoinLeaderboardResponseContent,
  PutUserProfileRequestContent,
  PutUserProfileResponseContent,
} from '../models';
import {
    BadRequestErrorResponseContentFromJSON,
    BadRequestErrorResponseContentToJSON,
    DeleteLeaderboardResponseContentFromJSON,
    DeleteLeaderboardResponseContentToJSON,
    DeleteModelResponseContentFromJSON,
    DeleteModelResponseContentToJSON,
    DeleteShoppingListResponseContentFromJSON,
    DeleteShoppingListResponseContentToJSON,
    GetLeaderboardResponseContentFromJSON,
    GetLeaderboardResponseContentToJSON,
    GetLeaderboardSubmissionResponseContentFromJSON,
    GetLeaderboardSubmissionResponseContentToJSON,
    GetModelConfigResponseResponseContentFromJSON,
    GetModelConfigResponseResponseContentToJSON,
    GetModelListResponseContentFromJSON,
    GetModelListResponseContentToJSON,
    GetModelRankingResponseContentFromJSON,
    GetModelRankingResponseContentToJSON,
    GetModelResponseResponseContentFromJSON,
    GetModelResponseResponseContentToJSON,
    GetShoppingListsResponseContentFromJSON,
    GetShoppingListsResponseContentToJSON,
    GetUserLeaderboardResponseContentFromJSON,
    GetUserLeaderboardResponseContentToJSON,
    GetUserProfileResponseContentFromJSON,
    GetUserProfileResponseContentToJSON,
    InternalFailureErrorResponseContentFromJSON,
    InternalFailureErrorResponseContentToJSON,
    NotAuthorizedErrorResponseContentFromJSON,
    NotAuthorizedErrorResponseContentToJSON,
    NotFoundErrorResponseContentFromJSON,
    NotFoundErrorResponseContentToJSON,
    PutLeaderboardRequestContentFromJSON,
    PutLeaderboardRequestContentToJSON,
    PutLeaderboardResponseContentFromJSON,
    PutLeaderboardResponseContentToJSON,
    PutLeaderboardSubmissionRequestContentFromJSON,
    PutLeaderboardSubmissionRequestContentToJSON,
    PutLeaderboardSubmissionResponseContentFromJSON,
    PutLeaderboardSubmissionResponseContentToJSON,
    PutModelConfigRequestContentFromJSON,
    PutModelConfigRequestContentToJSON,
    PutModelConfigResponseContentFromJSON,
    PutModelConfigResponseContentToJSON,
    PutModelRequestContentFromJSON,
    PutModelRequestContentToJSON,
    PutModelResponseContentFromJSON,
    PutModelResponseContentToJSON,
    PutShoppingListRequestContentFromJSON,
    PutShoppingListRequestContentToJSON,
    PutShoppingListResponseContentFromJSON,
    PutShoppingListResponseContentToJSON,
    PutUserExitLeaderboardRequestContentFromJSON,
    PutUserExitLeaderboardRequestContentToJSON,
    PutUserExitLeaderboardResponseContentFromJSON,
    PutUserExitLeaderboardResponseContentToJSON,
    PutUserJoinLeaderboardRequestContentFromJSON,
    PutUserJoinLeaderboardRequestContentToJSON,
    PutUserJoinLeaderboardResponseContentFromJSON,
    PutUserJoinLeaderboardResponseContentToJSON,
    PutUserProfileRequestContentFromJSON,
    PutUserProfileRequestContentToJSON,
    PutUserProfileResponseContentFromJSON,
    PutUserProfileResponseContentToJSON,
} from '../models';

export interface DeleteLeaderboardRequest {
    leaderboardId: string;
}

export interface DeleteModelRequest {
    modelId: string;
    userId: string;
}

export interface DeleteShoppingListRequest {
    shoppingListId: string;
}

export interface GetLeaderboardRequest {
    leaderboardId: string;
}

export interface GetLeaderboardSubmissionRequest {
    leaderboardId: string;
    userId?: string;
}

export interface GetModelConfigResponseRequest {
    finaleId: string;
    userId: string;
    inputBody: string;
}

export interface GetModelListRequest {
    userId: string;
}

export interface GetModelRankingRequest {
    finaleId: string;
    question: string;
}

export interface GetModelResponseRequest {
    finaleId: string;
    inputBody: string;
}

export interface GetShoppingListsRequest {
    nextToken?: string;
    pageSize?: number;
    shoppingListId?: string;
}

export interface GetUserLeaderboardRequest {
    userId: string;
}

export interface GetUserProfileRequest {
    userId: string;
}

export interface PutLeaderboardRequest {
    putLeaderboardRequestContent: PutLeaderboardRequestContent;
}

export interface PutLeaderboardSubmissionRequest {
    putLeaderboardSubmissionRequestContent: PutLeaderboardSubmissionRequestContent;
}

export interface PutModelRequest {
    putModelRequestContent: PutModelRequestContent;
}

export interface PutModelConfigRequest {
    putModelConfigRequestContent: PutModelConfigRequestContent;
}

export interface PutShoppingListRequest {
    putShoppingListRequestContent: PutShoppingListRequestContent;
}

export interface PutUserExitLeaderboardRequest {
    putUserExitLeaderboardRequestContent: PutUserExitLeaderboardRequestContent;
}

export interface PutUserJoinLeaderboardRequest {
    putUserJoinLeaderboardRequestContent: PutUserJoinLeaderboardRequestContent;
}

export interface PutUserProfileRequest {
    putUserProfileRequestContent: PutUserProfileRequestContent;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Handles deletion of a Model
     */
    async deleteLeaderboardRaw(requestParameters: DeleteLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteLeaderboardResponseContent>> {
        if (requestParameters.leaderboardId === null || requestParameters.leaderboardId === undefined) {
            throw new runtime.RequiredError('leaderboardId','Required parameter requestParameters.leaderboardId was null or undefined when calling deleteLeaderboard.');
        }

        const queryParameters: any = {};

        if (requestParameters.leaderboardId !== undefined) {
            queryParameters['leaderboardId'] = requestParameters.leaderboardId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leaderboard`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteLeaderboardResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles deletion of a Model
     */
    async deleteLeaderboard(requestParameters: DeleteLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteLeaderboardResponseContent> {
        const response = await this.deleteLeaderboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles deletion of a Model
     */
    async deleteModelRaw(requestParameters: DeleteModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteModelResponseContent>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling deleteModel.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteModel.');
        }

        const queryParameters: any = {};

        if (requestParameters.modelId !== undefined) {
            queryParameters['modelId'] = requestParameters.modelId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/model`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteModelResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles deletion of a Model
     */
    async deleteModel(requestParameters: DeleteModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteModelResponseContent> {
        const response = await this.deleteModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles deletion of a Shopping List
     */
    async deleteShoppingListRaw(requestParameters: DeleteShoppingListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteShoppingListResponseContent>> {
        if (requestParameters.shoppingListId === null || requestParameters.shoppingListId === undefined) {
            throw new runtime.RequiredError('shoppingListId','Required parameter requestParameters.shoppingListId was null or undefined when calling deleteShoppingList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shopping-list/{shoppingListId}`.replace(`{${"shoppingListId"}}`, encodeURIComponent(String(requestParameters.shoppingListId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteShoppingListResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles deletion of a Shopping List
     */
    async deleteShoppingList(requestParameters: DeleteShoppingListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteShoppingListResponseContent> {
        const response = await this.deleteShoppingListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getLeaderboardRaw(requestParameters: GetLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLeaderboardResponseContent>> {
        if (requestParameters.leaderboardId === null || requestParameters.leaderboardId === undefined) {
            throw new runtime.RequiredError('leaderboardId','Required parameter requestParameters.leaderboardId was null or undefined when calling getLeaderboard.');
        }

        const queryParameters: any = {};

        if (requestParameters.leaderboardId !== undefined) {
            queryParameters['leaderboardId'] = requestParameters.leaderboardId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leaderboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLeaderboardResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getLeaderboard(requestParameters: GetLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLeaderboardResponseContent> {
        const response = await this.getLeaderboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles upserting of a Shopping List
     */
    async getLeaderboardSubmissionRaw(requestParameters: GetLeaderboardSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLeaderboardSubmissionResponseContent>> {
        if (requestParameters.leaderboardId === null || requestParameters.leaderboardId === undefined) {
            throw new runtime.RequiredError('leaderboardId','Required parameter requestParameters.leaderboardId was null or undefined when calling getLeaderboardSubmission.');
        }

        const queryParameters: any = {};

        if (requestParameters.leaderboardId !== undefined) {
            queryParameters['leaderboardId'] = requestParameters.leaderboardId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leaderboard/submission`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLeaderboardSubmissionResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles upserting of a Shopping List
     */
    async getLeaderboardSubmission(requestParameters: GetLeaderboardSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLeaderboardSubmissionResponseContent> {
        const response = await this.getLeaderboardSubmissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getModelConfigResponseRaw(requestParameters: GetModelConfigResponseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetModelConfigResponseResponseContent>> {
        if (requestParameters.finaleId === null || requestParameters.finaleId === undefined) {
            throw new runtime.RequiredError('finaleId','Required parameter requestParameters.finaleId was null or undefined when calling getModelConfigResponse.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getModelConfigResponse.');
        }

        if (requestParameters.inputBody === null || requestParameters.inputBody === undefined) {
            throw new runtime.RequiredError('inputBody','Required parameter requestParameters.inputBody was null or undefined when calling getModelConfigResponse.');
        }

        const queryParameters: any = {};

        if (requestParameters.finaleId !== undefined) {
            queryParameters['finaleId'] = requestParameters.finaleId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.inputBody !== undefined) {
            queryParameters['inputBody'] = requestParameters.inputBody;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/model-config-response`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetModelConfigResponseResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getModelConfigResponse(requestParameters: GetModelConfigResponseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetModelConfigResponseResponseContent> {
        const response = await this.getModelConfigResponseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getModelListRaw(requestParameters: GetModelListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetModelListResponseContent>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getModelList.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/model-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetModelListResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getModelList(requestParameters: GetModelListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetModelListResponseContent> {
        const response = await this.getModelListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getModelRankingRaw(requestParameters: GetModelRankingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetModelRankingResponseContent>> {
        if (requestParameters.finaleId === null || requestParameters.finaleId === undefined) {
            throw new runtime.RequiredError('finaleId','Required parameter requestParameters.finaleId was null or undefined when calling getModelRanking.');
        }

        if (requestParameters.question === null || requestParameters.question === undefined) {
            throw new runtime.RequiredError('question','Required parameter requestParameters.question was null or undefined when calling getModelRanking.');
        }

        const queryParameters: any = {};

        if (requestParameters.finaleId !== undefined) {
            queryParameters['finaleId'] = requestParameters.finaleId;
        }

        if (requestParameters.question !== undefined) {
            queryParameters['question'] = requestParameters.question;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/model-ranking`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetModelRankingResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getModelRanking(requestParameters: GetModelRankingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetModelRankingResponseContent> {
        const response = await this.getModelRankingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getModelResponseRaw(requestParameters: GetModelResponseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetModelResponseResponseContent>> {
        if (requestParameters.finaleId === null || requestParameters.finaleId === undefined) {
            throw new runtime.RequiredError('finaleId','Required parameter requestParameters.finaleId was null or undefined when calling getModelResponse.');
        }

        if (requestParameters.inputBody === null || requestParameters.inputBody === undefined) {
            throw new runtime.RequiredError('inputBody','Required parameter requestParameters.inputBody was null or undefined when calling getModelResponse.');
        }

        const queryParameters: any = {};

        if (requestParameters.finaleId !== undefined) {
            queryParameters['finaleId'] = requestParameters.finaleId;
        }

        if (requestParameters.inputBody !== undefined) {
            queryParameters['inputBody'] = requestParameters.inputBody;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/model-response`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetModelResponseResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getModelResponse(requestParameters: GetModelResponseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetModelResponseResponseContent> {
        const response = await this.getModelResponseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getShoppingListsRaw(requestParameters: GetShoppingListsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetShoppingListsResponseContent>> {
        const queryParameters: any = {};

        if (requestParameters.nextToken !== undefined) {
            queryParameters['nextToken'] = requestParameters.nextToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.shoppingListId !== undefined) {
            queryParameters['shoppingListId'] = requestParameters.shoppingListId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shopping-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetShoppingListsResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getShoppingLists(requestParameters: GetShoppingListsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetShoppingListsResponseContent> {
        const response = await this.getShoppingListsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getUserLeaderboardRaw(requestParameters: GetUserLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserLeaderboardResponseContent>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserLeaderboard.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leaderboard/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserLeaderboardResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getUserLeaderboard(requestParameters: GetUserLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserLeaderboardResponseContent> {
        const response = await this.getUserLeaderboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getUserProfileRaw(requestParameters: GetUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserProfileResponseContent>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserProfile.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserProfileResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async getUserProfile(requestParameters: GetUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserProfileResponseContent> {
        const response = await this.getUserProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles upserting of a Shopping List
     */
    async putLeaderboardRaw(requestParameters: PutLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PutLeaderboardResponseContent>> {
        if (requestParameters.putLeaderboardRequestContent === null || requestParameters.putLeaderboardRequestContent === undefined) {
            throw new runtime.RequiredError('putLeaderboardRequestContent','Required parameter requestParameters.putLeaderboardRequestContent was null or undefined when calling putLeaderboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leaderboard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PutLeaderboardRequestContentToJSON(requestParameters.putLeaderboardRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutLeaderboardResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles upserting of a Shopping List
     */
    async putLeaderboard(requestParameters: PutLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PutLeaderboardResponseContent> {
        const response = await this.putLeaderboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles upserting of a Shopping List
     */
    async putLeaderboardSubmissionRaw(requestParameters: PutLeaderboardSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PutLeaderboardSubmissionResponseContent>> {
        if (requestParameters.putLeaderboardSubmissionRequestContent === null || requestParameters.putLeaderboardSubmissionRequestContent === undefined) {
            throw new runtime.RequiredError('putLeaderboardSubmissionRequestContent','Required parameter requestParameters.putLeaderboardSubmissionRequestContent was null or undefined when calling putLeaderboardSubmission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leaderboard/submission`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PutLeaderboardSubmissionRequestContentToJSON(requestParameters.putLeaderboardSubmissionRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutLeaderboardSubmissionResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles upserting of a Shopping List
     */
    async putLeaderboardSubmission(requestParameters: PutLeaderboardSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PutLeaderboardSubmissionResponseContent> {
        const response = await this.putLeaderboardSubmissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles upserting of a Shopping List
     */
    async putModelRaw(requestParameters: PutModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PutModelResponseContent>> {
        if (requestParameters.putModelRequestContent === null || requestParameters.putModelRequestContent === undefined) {
            throw new runtime.RequiredError('putModelRequestContent','Required parameter requestParameters.putModelRequestContent was null or undefined when calling putModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/model`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PutModelRequestContentToJSON(requestParameters.putModelRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutModelResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles upserting of a Shopping List
     */
    async putModel(requestParameters: PutModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PutModelResponseContent> {
        const response = await this.putModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async putModelConfigRaw(requestParameters: PutModelConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PutModelConfigResponseContent>> {
        if (requestParameters.putModelConfigRequestContent === null || requestParameters.putModelConfigRequestContent === undefined) {
            throw new runtime.RequiredError('putModelConfigRequestContent','Required parameter requestParameters.putModelConfigRequestContent was null or undefined when calling putModelConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/model-config`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PutModelConfigRequestContentToJSON(requestParameters.putModelConfigRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutModelConfigResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles fetching of Shopping List(s)
     */
    async putModelConfig(requestParameters: PutModelConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PutModelConfigResponseContent> {
        const response = await this.putModelConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles upserting of a Shopping List
     */
    async putShoppingListRaw(requestParameters: PutShoppingListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PutShoppingListResponseContent>> {
        if (requestParameters.putShoppingListRequestContent === null || requestParameters.putShoppingListRequestContent === undefined) {
            throw new runtime.RequiredError('putShoppingListRequestContent','Required parameter requestParameters.putShoppingListRequestContent was null or undefined when calling putShoppingList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/shopping-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PutShoppingListRequestContentToJSON(requestParameters.putShoppingListRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutShoppingListResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles upserting of a Shopping List
     */
    async putShoppingList(requestParameters: PutShoppingListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PutShoppingListResponseContent> {
        const response = await this.putShoppingListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles upserting of a Shopping List
     */
    async putUserExitLeaderboardRaw(requestParameters: PutUserExitLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PutUserExitLeaderboardResponseContent>> {
        if (requestParameters.putUserExitLeaderboardRequestContent === null || requestParameters.putUserExitLeaderboardRequestContent === undefined) {
            throw new runtime.RequiredError('putUserExitLeaderboardRequestContent','Required parameter requestParameters.putUserExitLeaderboardRequestContent was null or undefined when calling putUserExitLeaderboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leaderboard/exit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PutUserExitLeaderboardRequestContentToJSON(requestParameters.putUserExitLeaderboardRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutUserExitLeaderboardResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles upserting of a Shopping List
     */
    async putUserExitLeaderboard(requestParameters: PutUserExitLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PutUserExitLeaderboardResponseContent> {
        const response = await this.putUserExitLeaderboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles upserting of a Shopping List
     */
    async putUserJoinLeaderboardRaw(requestParameters: PutUserJoinLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PutUserJoinLeaderboardResponseContent>> {
        if (requestParameters.putUserJoinLeaderboardRequestContent === null || requestParameters.putUserJoinLeaderboardRequestContent === undefined) {
            throw new runtime.RequiredError('putUserJoinLeaderboardRequestContent','Required parameter requestParameters.putUserJoinLeaderboardRequestContent was null or undefined when calling putUserJoinLeaderboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leaderboard/join`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PutUserJoinLeaderboardRequestContentToJSON(requestParameters.putUserJoinLeaderboardRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutUserJoinLeaderboardResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles upserting of a Shopping List
     */
    async putUserJoinLeaderboard(requestParameters: PutUserJoinLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PutUserJoinLeaderboardResponseContent> {
        const response = await this.putUserJoinLeaderboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles upserting of a Shopping List
     */
    async putUserProfileRaw(requestParameters: PutUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PutUserProfileResponseContent>> {
        if (requestParameters.putUserProfileRequestContent === null || requestParameters.putUserProfileRequestContent === undefined) {
            throw new runtime.RequiredError('putUserProfileRequestContent','Required parameter requestParameters.putUserProfileRequestContent was null or undefined when calling putUserProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PutUserProfileRequestContentToJSON(requestParameters.putUserProfileRequestContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutUserProfileResponseContentFromJSON(jsonValue));
    }

    /**
     * Handles upserting of a Shopping List
     */
    async putUserProfile(requestParameters: PutUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PutUserProfileResponseContent> {
        const response = await this.putUserProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
