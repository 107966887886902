import {
  Container,
  ContentLayout,
  FileUpload,
  FormField,
  Header,
  Button,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

const ValidateTrainingData: React.FC = () => {
  const [fileOutput, setFileOutput] = useState<File[]>([]);
  const fileReader = new FileReader();
  const [errors, setErrors] = useState<string[]>();
  const [isValid, setIsValid] = useState<boolean | undefined>();
  const [textareaContent, setTextareaContent] = useState<string>("");

  const validateJsonl = (content: string) => {
    const lines = content.split("\n");
    const errorList: string[] = [];

    if (lines.length < 50) {
      errorList.push("Training data needs to have at least 50 rows.");
    } else {
      lines.forEach((line, index) => {
        try {
          const parsed = JSON.parse(line);

          // Check for required keys
          const requiredKeys = ["instruction", "context", "response"];
          const missingKeys = requiredKeys.filter((key) => !(key in parsed));

          if (missingKeys.length > 0) {
            errorList.push(
              `Error on line ${
                index + 1
              }: Missing required keys: ${missingKeys.join(", ")}`,
            );
          }

          // Check for Unicode characters
          const unicodeRegex = /[^\u0000-\u007F]/g;
          let match;
          while ((match = unicodeRegex.exec(line)) !== null) {
            errorList.push(
              `Unicode character "${match[0]}" detected on line ${
                index + 1
              }, position ${match.index + 1}`,
            );
          }
        } catch (err: any) {
          errorList.push(`Error on line ${index + 1}: ${err.message}`);
        }
      });
    }

    if (errorList.length > 0) {
      setErrors(errorList);
      setIsValid(false);
    } else {
      setIsValid(true);
    }
    return errorList;
  };

  const isValidExtension = (inputFilename: string) => {
    return inputFilename?.split(".").pop() === "jsonl";
  };

  const handleFileRead = () => {
    const content = fileReader.result;
    if (content) {
      setTextareaContent(content.toString()); // Set textarea content with file content
      // validateJsonl(content.toString()); // Validate the content
    }
  };

  const handleTextareaValidation = () => {
    setIsValid(undefined);
    setErrors(undefined);
    validateJsonl(textareaContent.trim());
  };

  const handleSaveJsonl = () => {
    const blob = new Blob([textareaContent], { type: "application/jsonl" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "train.jsonl";
    link.click();
  };

  useEffect(() => {}, []);

  return (
    <ContentLayout
      header={
        <Header
          variant="awsui-h1-sticky"
          description="Choose or paste your training data for validation."
        >
          Validate Training Data
        </Header>
      }
    >
      <Container>
        {/* File Upload Section */}
        <FormField
          errorText={
            errors ? (
              <div>
                {errors.map((error, index) => (
                  <div key={index}>{error}</div>
                ))}
              </div>
            ) : null
          }
          constraintText={
            isValid === true ? "Valid training data format!" : null
          }
        >
          {/* Textarea Section */}
          <FormField
            label="Paste JSONL Data"
            description="Paste your training data in JSONL format for validation."
            stretch
          >
            <AceEditor
              mode="java"
              theme="github"
              value={textareaContent}
              onChange={(value) => setTextareaContent(value)}
              name="jsonl-texteditor"
              style={{
                width: "100%",
                height: "300px",
              }}
              editorProps={{ $blockScrolling: true }}
            />
          </FormField>

          <FileUpload
            onChange={({ detail }) => {
              setIsValid(undefined);
              setErrors(undefined);
              setFileOutput(detail.value);
              if (!isValidExtension(detail.value[0].name)) {
                setErrors([
                  "Invalid file extension. Please use .jsonl file extension.",
                ]);
              } else {
                fileReader.onloadend = handleFileRead;
                fileReader.readAsText(detail.value[0]);
              }
            }}
            value={fileOutput}
            i18nStrings={{
              uploadButtonText: (isMultiple) =>
                isMultiple ? "Choose files" : "Upload jsonl file",
              dropzoneText: (isMultiple) =>
                isMultiple ? "Drop files to upload" : "Drop file to upload",
              removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
              limitShowFewer: "Show fewer files",
              limitShowMore: "Show more files",
              errorIconAriaLabel: "Error",
            }}
            showFileLastModified
            showFileSize
            showFileThumbnail
            tokenLimit={3}
          />
        </FormField>

        <div style={{ marginTop: "1rem" }} />

        <Button onClick={handleTextareaValidation} variant="primary">
          Validate JSONL
        </Button>

        <Button onClick={handleSaveJsonl} variant="normal">
          Save Training Data File
        </Button>
      </Container>
    </ContentLayout>
  );
};

export default ValidateTrainingData;
