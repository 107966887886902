/*! Copyright [Amazon.com](http://amazon.com/), Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0 */
import { useCognitoAuthContext } from "@aws-northstar/ui";
import NavHeader from "@aws-northstar/ui/components/AppLayout/components/NavHeader";
import getBreadcrumbs from "@aws-northstar/ui/components/AppLayout/utils/getBreadcrumbs";
import {
  BreadcrumbGroup,
  BreadcrumbGroupProps,
  SideNavigation,
} from "@cloudscape-design/components";
import AppLayout, {
  AppLayoutProps,
} from "@cloudscape-design/components/app-layout";
import { useGetUserLeaderboard } from "myapi-typescript-react-query-hooks";
import * as React from "react";
import { createContext, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LeaderboardContextState } from "./LeaderboardContextManagement";
import { NavItems } from "./navitems";
import Config from "../../config.json";
import Routes from "../Routes";
import "./app.css";

/**
 * Context for updating/retrieving the AppLayout.
 */
export const AppLayoutContext = createContext({
  appLayoutProps: {},
  setAppLayoutProps: (_: AppLayoutProps) => {},
});

export const UserContext = createContext({ userId: "", username: "" });

export const LeaderboardContext = createContext<LeaderboardContextState>({
  userLeaderboardInfo: {
    id: "",
    pendingSubmitCompletion: false,
    statusMessage: "",
  },
  setUserLeaderboardInfo: () => {},
});

/**
 * Defines the App layout and contains logic for routing.
 */
const App: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>();
  const [userId, setUserId] = useState<string>("");
  const [userLeaderboardInfo, setUserLeaderboardInfo] = useState({
    id: "",
    pendingSubmitCompletion: false,
    statusMessage: "",
  });
  const { getAuthenticatedUser } = useCognitoAuthContext();
  const getUserLeaderboard = useGetUserLeaderboard(
    {
      userId: userId,
    },
    { enabled: userId != "" },
  );

  const navigate = useNavigate();
  const [activeHref, setActiveHref] = useState("/");
  const [activeBreadcrumbs, setActiveBreadcrumbs] = useState<
    BreadcrumbGroupProps.Item[]
  >([{ text: "/", href: "/" }]);
  const [appLayoutProps, setAppLayoutProps] = useState<AppLayoutProps>({});
  const location = useLocation();

  useEffect(() => {
    if (getAuthenticatedUser) {
      const authUser = getAuthenticatedUser();
      setUsername(authUser?.getUsername()!);

      authUser?.getSession(() => {
        authUser.getUserAttributes((_, attributes) => {
          setEmail(attributes?.find((a) => a.Name === "email")?.Value);
          const currentUserId = attributes?.find((a) => a.Name === "sub")
            ?.Value;
          if (currentUserId) {
            setUserId(currentUserId);
          }
          console.log(
            "useEffect userId " +
              attributes?.find((a) => a.Name === "sub")?.Value,
          );
        });
      });
    }
  }, [getAuthenticatedUser, setUsername, setEmail]);

  useEffect(() => {
    if (userId) {
      getUserLeaderboard
        .refetch()
        .then((res) => {
          let joinedLeaderboardList: string[] = [];
          if (res.data) {
            joinedLeaderboardList = res.data.leaderboardList;
            if (joinedLeaderboardList.length > 0) {
              console.log("LeaderboardId: " + joinedLeaderboardList.at(0));
              setUserLeaderboardInfo({
                id: joinedLeaderboardList.at(0)!,
                pendingSubmitCompletion: res.data.pendingSubmitCompletion!,
                statusMessage: res.data.statusMessage!,
              });
            }
          }
        })
        .catch((err) => console.log("error: " + err));
    }
  }, [userId]);

  const setAppLayoutPropsSafe = useCallback(
    (props: AppLayoutProps) => {
      JSON.stringify(appLayoutProps) !== JSON.stringify(props) &&
        setAppLayoutProps(props);
    },
    [appLayoutProps],
  );

  useEffect(() => {
    setActiveHref(location.pathname);
    const breadcrumbs = getBreadcrumbs(location.pathname, location.search, "/");
    setActiveBreadcrumbs(breadcrumbs);
  }, [location]);

  const onNavigate = useCallback(
    (e: CustomEvent<{ href: string; external?: boolean }>) => {
      if (!e.detail.external) {
        e.preventDefault();
        setAppLayoutPropsSafe({
          contentType: undefined,
          splitPanelOpen: false,
          splitPanelSize: undefined,
          splitPanelPreferences: undefined,
        });
        navigate(e.detail.href);
      }
    },
    [navigate],
  );

  return (
    <UserContext.Provider value={{ userId: userId, username: username }}>
      <LeaderboardContext.Provider
        value={{ userLeaderboardInfo, setUserLeaderboardInfo }}
      >
        <AppLayoutContext.Provider
          value={{ appLayoutProps, setAppLayoutProps: setAppLayoutPropsSafe }}
        >
          <NavHeader
            title={Config.applicationName}
            logo={Config.logoWhite}
            user={
              username
                ? {
                    username,
                    email,
                  }
                : undefined
            }
            onSignout={() =>
              new Promise(() => {
                getAuthenticatedUser && getAuthenticatedUser()?.signOut();
                window.location.href = "/";
              })
            }
          />
          <AppLayout
            breadcrumbs={
              <BreadcrumbGroup
                onFollow={onNavigate}
                items={activeBreadcrumbs}
              />
            }
            toolsHide
            navigation={
              <SideNavigation
                header={{ text: Config.applicationName, href: "/" }}
                activeHref={activeHref}
                onFollow={onNavigate}
                items={NavItems}
              />
            }
            content={
              <>
                <Routes />
              </>
            }
            {...appLayoutProps}
          />
        </AppLayoutContext.Provider>
      </LeaderboardContext.Provider>
    </UserContext.Provider>
  );
};

export default App;
