/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LeaderboardStructure } from './LeaderboardStructure';
import {
    LeaderboardStructureFromJSON,
    LeaderboardStructureFromJSONTyped,
    LeaderboardStructureToJSON,
} from './LeaderboardStructure';

/**
 * 
 * @export
 * @interface GetLeaderboardResponseContent
 */
export interface GetLeaderboardResponseContent {
    /**
     * 
     * @type {Array<LeaderboardStructure>}
     * @memberof GetLeaderboardResponseContent
     */
    leaderboardList: Array<LeaderboardStructure>;
}


/**
 * Check if a given object implements the GetLeaderboardResponseContent interface.
 */
export function instanceOfGetLeaderboardResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "leaderboardList" in value;

    return isInstance;
}

export function GetLeaderboardResponseContentFromJSON(json: any): GetLeaderboardResponseContent {
    return GetLeaderboardResponseContentFromJSONTyped(json, false);
}

export function GetLeaderboardResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLeaderboardResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'leaderboardList': ((json['leaderboardList'] as Array<any>).map(LeaderboardStructureFromJSON)),
    };
}

export function GetLeaderboardResponseContentToJSON(value?: GetLeaderboardResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'leaderboardList': ((value.leaderboardList as Array<any>).map(LeaderboardStructureToJSON)),
    };
}

