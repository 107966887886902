/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Defines the id attribute of the Shopping List
 * @export
 * @interface PutLeaderboardRequestContent
 */
export interface PutLeaderboardRequestContent {
    /**
     * 
     * @type {string}
     * @memberof PutLeaderboardRequestContent
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PutLeaderboardRequestContent
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof PutLeaderboardRequestContent
     */
    leaderboardStartDate: number;
    /**
     * 
     * @type {number}
     * @memberof PutLeaderboardRequestContent
     */
    leaderboardEndDate: number;
    /**
     * 
     * @type {string}
     * @memberof PutLeaderboardRequestContent
     */
    score: string;
    /**
     * 
     * @type {string}
     * @memberof PutLeaderboardRequestContent
     */
    instructionSetS3Link: string;
}


/**
 * Check if a given object implements the PutLeaderboardRequestContent interface.
 */
export function instanceOfPutLeaderboardRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "leaderboardStartDate" in value;
    isInstance = isInstance && "leaderboardEndDate" in value;
    isInstance = isInstance && "score" in value;
    isInstance = isInstance && "instructionSetS3Link" in value;

    return isInstance;
}

export function PutLeaderboardRequestContentFromJSON(json: any): PutLeaderboardRequestContent {
    return PutLeaderboardRequestContentFromJSONTyped(json, false);
}

export function PutLeaderboardRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutLeaderboardRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'leaderboardStartDate': json['leaderboardStartDate'],
        'leaderboardEndDate': json['leaderboardEndDate'],
        'score': json['score'],
        'instructionSetS3Link': json['instructionSetS3Link'],
    };
}

export function PutLeaderboardRequestContentToJSON(value?: PutLeaderboardRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'leaderboardStartDate': value.leaderboardStartDate,
        'leaderboardEndDate': value.leaderboardEndDate,
        'score': value.score,
        'instructionSetS3Link': value.instructionSetS3Link,
    };
}

