/* tslint:disable */
/* eslint-disable */
/**
 * MyApi
 * A sample smithy api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Defines the id attribute of the Shopping List
 * @export
 * @interface PutModelRequestContent
 */
export interface PutModelRequestContent {
    /**
     * 
     * @type {string}
     * @memberof PutModelRequestContent
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof PutModelRequestContent
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PutModelRequestContent
     */
    trainingJobName: string;
    /**
     * 
     * @type {string}
     * @memberof PutModelRequestContent
     */
    modelId?: string;
    /**
     * 
     * @type {string}
     * @memberof PutModelRequestContent
     */
    modelOutputs?: string;
}


/**
 * Check if a given object implements the PutModelRequestContent interface.
 */
export function instanceOfPutModelRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "trainingJobName" in value;

    return isInstance;
}

export function PutModelRequestContentFromJSON(json: any): PutModelRequestContent {
    return PutModelRequestContentFromJSONTyped(json, false);
}

export function PutModelRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutModelRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'name': json['name'],
        'trainingJobName': json['trainingJobName'],
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
        'modelOutputs': !exists(json, 'modelOutputs') ? undefined : json['modelOutputs'],
    };
}

export function PutModelRequestContentToJSON(value?: PutModelRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'name': value.name,
        'trainingJobName': value.trainingJobName,
        'modelId': value.modelId,
        'modelOutputs': value.modelOutputs,
    };
}

